import { ITrainingProgram, ITrainingRequestContext } from '@/common/api/trainings';
import { createContext } from 'react';

export interface IRequestTrainingFormContext {
  formContext: (ITrainingRequestContext & { programs: ITrainingProgram[] }) | undefined;
}

export const RequestTrainingFormContext = createContext({
  formContext: undefined,
} as IRequestTrainingFormContext);
