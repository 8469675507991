import { FC, useEffect } from 'react';
import { InputText } from '@unbooking/ui-kit';
import InputWarning from '@/components/forms/RequestTrainingForm/FormStepComponents/components/InputWarning';
import YesNoInput from '@/components/inputs/YesNoInput';
import { IEditComponent } from './types';
import {
  FieldValidationWarning,
  nonEmptyValidation,
} from '@/components/forms/RequestTrainingForm/common/FieldValidationWarning';

export const ParticipantsEdit: FC<IEditComponent> = ({
  trainingRequest,
  updateTrainingRequest,
  setConfirmEnabled,
}) => {
  const numOfAttendeesValidator = nonEmptyValidation(trainingRequest.num_of_attendees);
  const elearingCompletedValidator = nonEmptyValidation(trainingRequest.elearning_completed);
  const confirmEnabled = [numOfAttendeesValidator, elearingCompletedValidator].every(
    validator => validator === null,
  );

  useEffect(() => {
    setConfirmEnabled(confirmEnabled);
  }, [confirmEnabled]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col items-stretch mb-3 gap-2">
        <div>Number of participants</div>
        <InputText
          light
          value={trainingRequest.num_of_attendees ?? ''}
          onChange={e => updateTrainingRequest('num_of_attendees', e.target.value)}
          name="num-of-attendees"
          placeholder="..."
        />
        <FieldValidationWarning light result={numOfAttendeesValidator} />
      </div>
      <div className="flex flex-col items-stretch mb-2 gap-2">
        <div>
          Please confirm if all the WFP participating drivers completed the mandatory e-learnings
          (including the Road Safety e-learning)
        </div>
        <YesNoInput
          light
          value={trainingRequest.elearning_completed}
          onChange={value => updateTrainingRequest('elearning_completed', value)}
        />
        <FieldValidationWarning light result={elearingCompletedValidator} />
        {trainingRequest.elearning_completed === false && (
          <InputWarning
            text={
              'Please note that mandatory learning is an essential tool to make the most out of the in-person training. We recommend all trainees complete it before the live session takes place.'
            }
          />
        )}
      </div>
    </div>
  );
};

export default ParticipantsEdit;
