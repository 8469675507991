import React from 'react';
import { ITrainingProgram } from '@/common/api/trainings';

export enum RequestTrainingStep {
  PROGRAM = 'programStep',
  AGENCY = 'agencyStep',
  TIME_AND_LOCATION = 'timeAndLocationStep',
  VEHICLES = 'vehiclesStep',
  ATTENDEES = 'attendeesStep',
  COMMUNICATIONS = 'communicationsStep',
  TRAINER = 'trainerStep',
  CONFIRM = 'confirmStep',
}

export interface IContactPerson {
  id?: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
}

export enum SupervisorRole {
  CO_HEAD_OF_OFFICE = 'CO Head of Office ',
  ADMIN_OFFICER = 'Admin Officer',
  SECURITY_OFFICER = 'Security Officer',
  FLEET_MANAGER = 'Fleet Manager',
}

export interface ISupervisor extends IContactPerson {
  role: SupervisorRole | null;
  role_display?: string;
}

export interface IProgramStepData {
  program: ITrainingProgram | null;
}

export interface IAgencyStepData {
  agencies: string[];
  other_agencies: string;
}
export interface ITimeAndLocationStepData {
  start_date: string;
  end_date: string;
  reason: string;
  weekend_training_available?: boolean;
  weekend_training_days: string | null;
  country_location: string;
  preferred_place: string;
  projector_available?: boolean | null;
  cones_available?: boolean | null;
  co_working_days: string;
  co_working_hours: string;
}
export interface IVehiclesStepData {
  av_available: boolean | null;
  safe_area: boolean | null;
  recovery_equipment: string[];
}
export interface IAttendeesStepData {
  num_of_attendees: string;
  elearning_completed: boolean | null;
}
export interface ICommunicationsStepData {
  focal_point: IContactPerson | null;
  supervisor: ISupervisor;
}
export interface ITrainerStepData {
  trainers_require_visa: boolean | null;
  trainers_require_visa_comment: string;
  trainers_require_medical_clearence: boolean | null;
  trainers_require_medical_clearence_comment: string;
  travel_advice: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
interface IConfirmStepData {}

export type RequestTrainingFormStepData =
  | IProgramStepData
  | IAgencyStepData
  | ITimeAndLocationStepData
  | IVehiclesStepData
  | IAttendeesStepData
  | ICommunicationsStepData
  | ITrainerStepData
  | IConfirmStepData;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type RequestTrainingFormField = KeysOfUnion<RequestTrainingFormStepData>;

type OtherFormFields = 'program';
export type RequestTrainingFormSubmission = Record<RequestTrainingFormField | OtherFormFields, any>;

export interface IRequestTrainingFormData {
  [RequestTrainingStep.PROGRAM]: IProgramStepData;
  [RequestTrainingStep.AGENCY]: IAgencyStepData;
  [RequestTrainingStep.TIME_AND_LOCATION]: ITimeAndLocationStepData;
  [RequestTrainingStep.VEHICLES]: IVehiclesStepData;
  [RequestTrainingStep.ATTENDEES]: IAttendeesStepData;
  [RequestTrainingStep.COMMUNICATIONS]: ICommunicationsStepData;
  [RequestTrainingStep.TRAINER]: ITrainerStepData;
  [RequestTrainingStep.CONFIRM]: IConfirmStepData;
}

export interface IRequestTrainingFormStepProps {
  formData: IRequestTrainingFormData;
  updateFormData: (field: RequestTrainingFormField, value: any) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setSelectedStep: React.Dispatch<React.SetStateAction<RequestTrainingStep>>;
  updateStepCompleted: (step: RequestTrainingStep, completed: boolean) => void;
}
