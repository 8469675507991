import { FC } from 'react';

interface IIconTitle {
  label: string;
  icon: React.FC<{ highlight?: boolean }>;
}

export const IconTitle: FC<IIconTitle> = ({ label, icon: Icon }) => {
  return (
    <div className="flex flex-row items-start justify-start mb-10">
      <div className="bg-highlight opacity-20 rounded-full w-10 h-10"></div>
      <div className="fill-highlight relative right-10 opacity-100 w-10 h-10 flex justify-center items-center">
        <Icon highlight={true} />
      </div>
      <div className="text-2xl font-light text-white pt-1 -ml-7">{label}</div>
    </div>
  );
};

export default IconTitle;
