import { FC } from 'react';
import { CheckedIcon } from '@icons';
import cn from 'classnames';

type SelectOptionValue = null | string | number | boolean;

interface ISelectOption {
  label: string;
  value: SelectOptionValue;
}

interface IListSelectInput {
  value: SelectOptionValue;
  onChange: (value: SelectOptionValue) => void;
  options: ISelectOption[];
  light?: boolean;
}

export const ListSelectInput: FC<IListSelectInput> = ({
  options,
  value,
  onChange,
  light = false,
}) => {
  const valueIsSelected = options.map(option => option.value).includes(value);

  const notCheckedClass = 'border border-input-border';
  const checkedClass = 'border-2 border-input-green';

  const getLabelClass = (optionValue: SelectOptionValue) =>
    optionValue === value ? `-ml-px` : undefined;

  const getIcon = (optionValue: SelectOptionValue) =>
    optionValue === value ? <CheckedIcon /> : null;

  return (
    <div className={cn('flex flex-col gap-2')}>
      {options.map(({ label, value: optionValue }, index) => (
        <div
          className={cn(
            'cursor-pointer w-full py-3 px-4 text-sm flex justify-between',
            light ? 'bg-white text-black' : 'bg-input-bg font-light text-white',
            optionValue === value ? checkedClass : notCheckedClass,
          )}
          onClick={() => onChange(optionValue)}
          key={index}
        >
          <div className={getLabelClass(optionValue)}>{label}</div> {getIcon(optionValue)}
        </div>
      ))}
    </div>
  );
};

export default ListSelectInput;
