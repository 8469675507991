import { Button } from '@unbooking/ui-kit';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ITrainingProgramContent } from '@/common/api/cms';
import { CalendarOptionIcon } from '@icons';
import { PATHS } from '@/common/path';

interface ITrainingProgramDescription {
  trainingProgramContent: ITrainingProgramContent;
}

export const TrainingProgramDescription: FC<ITrainingProgramDescription> = ({
  trainingProgramContent,
}) => {
  const trainingUrl = `${PATHS.requestTraining}?program=${trainingProgramContent.training_program.id}`;

  return (
    <div className="bg-training-details h-full w-full">
      <div className="container mx-auto w-full h-max flex flex-1 flex-col justify-start min-h-full text-white gap-5 py-10">
        <div className="flex flex-row items-start justify-between">
          <div>
            <h1
              className="text-4xl"
              dangerouslySetInnerHTML={{ __html: trainingProgramContent.training_program.title }}
            />
            <p
              className="font-light"
              dangerouslySetInnerHTML={{ __html: trainingProgramContent.description }}
            />
          </div>
          <NavLink to={trainingUrl}>
            <Button label="Request this training" endIcon={<CalendarOptionIcon />} />
          </NavLink>
        </div>
        <div className="lg:columns-2 gap-10">
          {trainingProgramContent.paragraphs.map(paragraph => (
            <div className="mb-5" key={paragraph.id}>
              {paragraph.title && (
                <p
                  className="cms-content font-bold mb-3"
                  dangerouslySetInnerHTML={{ __html: paragraph.title }}
                />
              )}
              <p
                className="font-light"
                dangerouslySetInnerHTML={{ __html: paragraph.description }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
