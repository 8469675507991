import { FC, useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { INITIAL_FORM_DATA } from './common/initialData';
import {
  IRequestTrainingFormData,
  RequestTrainingFormField,
  RequestTrainingStep,
} from './common/interfaces';
import RequestTrainingFormSidebar from './RequestTrainingFormSidebar';
import { getNextFormStep, getPreviousStep, getStepFormData } from './RequestTrainingFormSteps';
import { RequestTrainingFormContext } from './common/formContext';
import {
  getTrainingPrograms,
  getTrainingRequestContext,
  ITrainingProgram,
  ITrainingRequestContext,
} from '@/common/api/trainings';
import { Spinner } from '@components';

const INITIAL_CONTEXT: ITrainingRequestContext & { programs: ITrainingProgram[] } = {
  programs: [],
  agencies: [],
  countries: [],
  recovery_equipment: [],
  supervisor_roles: [],
};

interface IRequestTrainingForm {
  trainingProgram?: ITrainingProgram | null;
}

export const RequestTrainingForm: FC<IRequestTrainingForm> = ({ trainingProgram }) => {
  // If training program selected - move to Agency step and fill in initial data
  const [selectedStep, setSelectedStep] = useState<RequestTrainingStep>(
    trainingProgram ? RequestTrainingStep.AGENCY : RequestTrainingStep.PROGRAM,
  );
  const [formData, setFormData] = useState<IRequestTrainingFormData>(() => {
    return trainingProgram
      ? { ...INITIAL_FORM_DATA, [RequestTrainingStep.PROGRAM]: { program: trainingProgram } }
      : INITIAL_FORM_DATA;
  });
  const [stepsCompleted, setStepsCompleted] = useState<Record<RequestTrainingStep, boolean>>(() => {
    const result = {} as Record<RequestTrainingStep, boolean>;
    Object.values(RequestTrainingStep).forEach(step => {
      result[step] = false;
    });
    if (trainingProgram) {
      result[RequestTrainingStep.PROGRAM] = true;
    }
    return result;
  });

  const StepComponent = getStepFormData(selectedStep).component;

  const goToPreviousStep = () => {
    setSelectedStep(prev => getPreviousStep(prev));
  };

  const goToNextStep = () => {
    setSelectedStep(prev => getNextFormStep(prev));
  };

  const updateStepCompleted = (step: RequestTrainingStep, completed: boolean) => {
    setStepsCompleted(prev => ({ ...prev, [step]: completed }));
  };

  const updateFormData = useCallback(
    (formStep: RequestTrainingStep, field: RequestTrainingFormField, value: any) => {
      setFormData(prev => ({
        ...prev,
        [formStep]: {
          ...prev[formStep],
          [field]: value,
        },
      }));
    },
    [],
  );

  const updateCurrentStepData = useCallback(
    (field: RequestTrainingFormField, value: any) => {
      updateFormData(selectedStep, field, value);
    },
    [selectedStep, updateFormData],
  );

  const getFormContext = async () => {
    const [context, programs] = await Promise.all([
      getTrainingRequestContext().then(response => response.data),
      getTrainingPrograms().then(response => response.data.results),
    ]);
    return { ...context, programs };
  };

  const {
    isError,
    isLoading,
    data: formContext,
  } = useQuery({
    queryKey: ['request-training-context'],
    queryFn: getFormContext,
    placeholderData: () => INITIAL_CONTEXT,
  });

  return (
    <RequestTrainingFormContext.Provider value={{ formContext }}>
      <div className="flex flex-row gap-10 p-8">
        <RequestTrainingFormSidebar
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          stepsCompleted={stepsCompleted}
        />
        {isLoading ? (
          <div className="flex justify-center items-center w-full">
            <Spinner />
          </div>
        ) : isError ? (
          <div className="text-white">
            There was an unexpected error while loading the form data.
          </div>
        ) : (
          <StepComponent
            formData={formData}
            updateFormData={updateCurrentStepData}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            setSelectedStep={setSelectedStep}
            updateStepCompleted={updateStepCompleted}
          />
        )}
      </div>
    </RequestTrainingFormContext.Provider>
  );
};

export default RequestTrainingForm;
