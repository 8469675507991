import { Button } from '@unbooking/ui-kit';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { CalendarOptionIcon } from '@icons';
import { PATHS } from '@/common/path';

export const BookTrainingNavButton: FC = () => {
  return (
    <NavLink to={PATHS.requestTraining}>
      <Button label="Request a training" endIcon={<CalendarOptionIcon />} />
    </NavLink>
  );
};

export default BookTrainingNavButton;
