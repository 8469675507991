import { IContactPerson, IRequestTrainingFormData, RequestTrainingStep } from './interfaces';

const initialPerson: IContactPerson = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
};

const INITIAL_FORM_DATA: IRequestTrainingFormData = {
  [RequestTrainingStep.PROGRAM]: {
    program: null,
  },
  [RequestTrainingStep.AGENCY]: {
    agencies: [],
    other_agencies: '',
  },
  [RequestTrainingStep.TIME_AND_LOCATION]: {
    start_date: '',
    end_date: '',
    reason: '',
    weekend_training_available: true,
    weekend_training_days: null,
    country_location: '',
    preferred_place: '',
    // TODO Hide for now
    // projector_available: null,
    // cones_available: null,
    co_working_days: '',
    co_working_hours: '',
  },
  [RequestTrainingStep.VEHICLES]: {
    av_available: null,
    safe_area: null,
    recovery_equipment: [],
  },
  [RequestTrainingStep.ATTENDEES]: {
    num_of_attendees: '',
    elearning_completed: null,
  },
  [RequestTrainingStep.COMMUNICATIONS]: {
    focal_point: {
      ...initialPerson,
    },
    supervisor: {
      ...initialPerson,
      role: null,
    },
  },
  [RequestTrainingStep.TRAINER]: {
    trainers_require_visa: null,
    trainers_require_visa_comment: '',
    trainers_require_medical_clearence: null,
    trainers_require_medical_clearence_comment: '',
    travel_advice: '',
  },
  [RequestTrainingStep.CONFIRM]: {},
};

export { INITIAL_FORM_DATA };
