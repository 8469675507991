import { FC, ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

interface ITip {
  text?: string;
  isVisible?: boolean;
  className?: string;
  html?: ReactElement;
  children?: React.ReactNode;
}

export const Tip: FC<ITip> = ({ className, text, html, isVisible = true, children }) => {
  return (
    <span
      role="tooltip"
      className={className}
      data-tooltip-id="global-tooltip"
      data-tooltip-content={isVisible && text ? text : ''}
      data-tooltip-html={isVisible && html ? renderToStaticMarkup(html) : null}
    >
      {children}
    </span>
  );
};

export default Tip;
