import React, { FC, useState } from 'react';
import { Button } from '@unbooking/ui-kit';
import {
  AgencyIcon,
  AttendeesIcon,
  CommunicationIcon,
  EditIcon,
  LocationIcon,
  TrainerIcon,
  VehicleIcon,
} from '@icons';
import {
  getAgencyData,
  getAttendeesData,
  getCommunicationsData,
  getTimeAndLocationData,
  getTrainerData,
  getVehiclesData,
} from '../utils';
import { EditTrainingSectionModal } from '../../edit';
import { IEditSection, NeedTrainingProp } from '../../edit/types';
import { IconWrapper } from '@/components/common';

const RequestDetails: FC<NeedTrainingProp> = ({ trainingRequest }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editSection, setEditSection] = useState<IEditSection>();

  const sections: IEditSection[] = [
    {
      title: 'Agency',
      icon: (
        <IconWrapper>
          <AgencyIcon size="30" />
        </IconWrapper>
      ),
      data: getAgencyData(trainingRequest),
      sectionType: 'agency',
    },
    {
      title: 'Time and location',

      icon: (
        <IconWrapper>
          <LocationIcon highlight size="30" />
        </IconWrapper>
      ),
      data: getTimeAndLocationData(trainingRequest),
      sectionType: 'time-and-location',
    },
    {
      title: 'Participants',
      icon: (
        <IconWrapper>
          <AttendeesIcon size="30" />
        </IconWrapper>
      ),
      data: getAttendeesData(trainingRequest),
      sectionType: 'participants',
    },
    {
      title: 'Communications',
      icon: (
        <IconWrapper>
          <CommunicationIcon size="30" />
        </IconWrapper>
      ),
      data: getCommunicationsData(trainingRequest),
      sectionType: 'communications',
    },
    {
      title: 'Vehicles',
      icon: (
        <IconWrapper>
          <VehicleIcon size="30" />
        </IconWrapper>
      ),
      data: getVehiclesData(trainingRequest),
      sectionType: 'vehicles',
    },
    {
      title: 'Travel requirements for trainers',
      icon: (
        <IconWrapper>
          <TrainerIcon size="30" />
        </IconWrapper>
      ),
      data: getTrainerData(trainingRequest),
      sectionType: 'trainers',
    },
  ];

  return (
    <div className="mx-5">
      <ul className="bg-neutral-200 w-fit p-3">
        <li>
          <b>Request details</b>
        </li>
      </ul>
      <section className="bg-catskill-white p-5">
        <div className="bg-white-light">
          <div className="border-t-2 border-b-2 flex border-gray-300 flex-wrap">
            {sections.map(section => (
              <div
                className="basis-1/3 flex-grow min-w-[min(500px,100%)] p-5 border-r-2 border-b-2 border-dotted border-gray-300"
                key={section.title}
              >
                <div className="flex gap-2 items-center fill-highlight text-xl">
                  {section.icon} <b>{section.title}</b>
                  {section.sectionType && (
                    <>
                      <div className="flex-1" />
                      <Button
                        label="Edit"
                        startIcon={<EditIcon />}
                        plain
                        color="primary"
                        onClick={() => {
                          setEditSection(section);
                          setEditModalOpen(true);
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="mt-8 flex flex-col gap-4">
                  {section.data.map(({ label, value, type }, index) => (
                    <div className="flex items-baseline gap-4" key={index}>
                      {type === 'subtitle' ? (
                        <label className="text-oslo-gray font-bold flex-1">{label}</label>
                      ) : (
                        <>
                          <label className="text-oslo-gray basis-[240px] flex-[60%]">{label}</label>
                          <div className="flex-[40%]">{value}</div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <EditTrainingSectionModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        section={editSection}
        trainingRequest={trainingRequest}
      />
    </div>
  );
};

export default RequestDetails;
