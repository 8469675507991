import React, { FC } from 'react';
import {
  Dialog,
  Heading,
  Modal as AriaModal,
  ModalOverlay,
  ModalOverlayProps,
} from 'react-aria-components';
import cn from 'classnames';
import { Button } from '@unbooking/ui-kit';
import { CloseIcon } from '@icons';

interface IModal extends ModalOverlayProps {
  isOpen?: boolean;
  onClose: () => void;
  title?: string | React.JSX.Element;
  size?: 'small' | 'medium' | 'big';
  wrapperClassName?: string;
  children?: React.ReactNode;
}

export const Modal: FC<IModal> & IModalComposition = ({
  isOpen = false,
  onClose,
  title,
  size = 'medium',
  wrapperClassName,
  className,
  children,
  ...props
}) => {
  return (
    <ModalOverlay
      className={cn(
        'fixed top-0 left-0 w-screen h-[var(--visual-viewport-height)] bg-black/50 z-[100]',
        'flex items-center justify-center',
        'data-[entering]:animate-modal-enter data-[exiting]:animate-modal-exit',
        wrapperClassName,
      )}
      isOpen={isOpen}
      onOpenChange={onClose}
      {...props}
    >
      <AriaModal
        className={cn(
          'rounded outline-0 shadow-lg bg-catskill-white data-[entering]:animate-zoom-in',
          { 'w-1/3 min-w-[min(100%,500px)]': size === 'small' },
          { 'w-1/2 min-w-[min(100%,700px)]': size === 'medium' },
          { 'w-full mx-8': size === 'big' },
          className,
        )}
      >
        <Dialog className="px-6 pb-6 pt-3 relative">
          {title && <Header align={size === 'small' ? 'center' : 'left'}>{title}</Header>}
          <span className="absolute top-0 right-0">
            <Button
              color="black"
              plain
              label=""
              startIcon={<CloseIcon color="black" />}
              onClick={onClose}
            />
          </span>
          {children}
        </Dialog>
      </AriaModal>
    </ModalOverlay>
  );
};

interface IHeader {
  align?: 'left' | 'center';
  className?: string;
  children?: React.ReactNode;
}
const Header: FC<IHeader> = ({ align = 'left', className, children }) => {
  return (
    <>
      <Heading
        slot="title"
        className={cn(
          'text-2xl text-sky-600 font-semibold flex items-center mb-2',
          { 'justify-start': align === 'left' },
          { 'justify-center': align === 'center' },
          className,
        )}
      >
        {children}
      </Heading>
      <hr className="border-b border-gray-lighter -mx-6" />
    </>
  );
};

interface IBody {
  children?: React.ReactNode;
}
const Body: FC<IBody> = ({ children }) => {
  return <div className="max-h-[calc(90vh-200px)] overflow-y-auto pt-8 pb-10">{children}</div>;
};

interface IFooter {
  buttonPosition?: 'center' | 'right';
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmEnabled?: boolean;
  children?: React.ReactNode;
}
const Footer: FC<IFooter> = ({
  buttonPosition = 'center',
  onConfirm,
  onCancel,
  confirmEnabled = true,
  children,
}) => {
  return (
    <div
      className={cn('flex items-baseline pt-4', {
        'justify-evenly': buttonPosition === 'center',
        'justify-end gap-x-8': buttonPosition === 'right',
      })}
    >
      {children ? (
        children
      ) : (
        <>
          <Button label="Cancel" onClick={onCancel} color="white" />
          <Button label="Confirm" onClick={onConfirm} disabled={!confirmEnabled} />
        </>
      )}
    </div>
  );
};

interface IModalComposition {
  Header: typeof Header;
  Body: typeof Body;
  Footer: typeof Footer;
}

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
