import { FC } from 'react';

interface IInputWarning {
  text: string;
}

export const InputWarning: FC<IInputWarning> = ({ text }) => {
  return <div className="bg-yellow-400 py-3.5 px-2.5 text-sm">{text}</div>;
};

export default InputWarning;
