export const BookIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_636_1623)">
      <path d="M14.2499 14.875H9.24994V16.125H14.2499V14.875Z" />
      <path d="M7.99994 14.875H6.74994V16.125H7.99994V14.875Z" />
      <path d="M14.2499 11.75H9.24994V13H14.2499V11.75Z" />
      <path d="M7.99994 11.75H6.74994V13H7.99994V11.75Z" />
      <path d="M14.2499 8.625H9.24994V9.875H14.2499V8.625Z" />
      <path d="M7.99994 8.625H6.74994V9.875H7.99994V8.625Z" />
      <path d="M16.125 3.625H14.25V3C14.25 2.66848 14.1183 2.35054 13.8839 2.11612C13.6495 1.8817 13.3315 1.75 13 1.75H8C7.66848 1.75 7.35054 1.8817 7.11612 2.11612C6.8817 2.35054 6.75 2.66848 6.75 3V3.625H4.875C4.54348 3.625 4.22554 3.7567 3.99112 3.99112C3.7567 4.22554 3.625 4.54348 3.625 4.875V18C3.625 18.3315 3.7567 18.6495 3.99112 18.8839C4.22554 19.1183 4.54348 19.25 4.875 19.25H16.125C16.4565 19.25 16.7745 19.1183 17.0089 18.8839C17.2433 18.6495 17.375 18.3315 17.375 18V4.875C17.375 4.54348 17.2433 4.22554 17.0089 3.99112C16.7745 3.7567 16.4565 3.625 16.125 3.625ZM8 3H13V5.5H8V3ZM16.125 18H4.875V4.875H6.75V6.75H14.25V4.875H16.125V18Z" />
    </g>
    <defs>
      <clipPath id="clip0_636_1623">
        <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default BookIcon;
