import { FC } from 'react';
import { Button } from '@unbooking/ui-kit';

interface IFormSummarySection {
  icon?: React.JSX.Element;
  title: string;
  data: { value: string; label: string }[];
  onEdit?: () => void;
}

const FormSummarySection: FC<IFormSummarySection> = ({ icon, title, data, onEdit }) => {
  return (
    <section className="my-8">
      <div className="flex justify-between items-center border-b pb-1">
        <h3 className="fill-highlight flex gap-2 items-center">
          {icon}
          {title}
        </h3>
        <Button plain={true} onClick={onEdit} label="Edit" color="white" />
      </div>
      {data.map(({ value, label }) => (
        <div className="flex gap-6 mt-4 mb-2" key={label}>
          <div className="w-[230px] text-gray-lighter font-normal">{label}</div>
          <div className="flex-1">{value}</div>
        </div>
      ))}
    </section>
  );
};
export default FormSummarySection;
