import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ITrainingProgram } from '@/common/api/trainings';
import { getBgImageStyle } from '@/common/styles';

interface ITrainingProgramTile {
  trainingProgram: ITrainingProgram;
  className?: string;
}

export const TrainingProgramTile: FC<ITrainingProgramTile> = ({ trainingProgram, className }) => {
  return (
    <NavLink to={`/training-programs/${trainingProgram.id}`} className={className}>
      <div
        style={getBgImageStyle(trainingProgram.background_image_url)}
        className={`bg-no-repeat bg-cover bg-center h-56 overflow-y-hidden`}
      >
        <div className="flex flex-1 flex-col justify-end items-baseline min-h-full text-white from-training-tile-start to-training-tile-end p-2">
          <div className="bg-black/80 p-2">
            <h1 className="text-3xl mb-2">{trainingProgram.title}</h1>
            <div
              className="text-base font-light"
              dangerouslySetInnerHTML={{ __html: trainingProgram.description }}
            />
          </div>
        </div>
      </div>
    </NavLink>
  );
};
