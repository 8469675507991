import { FC } from 'react';
import { IBasePageComponent } from '@/common/interfaces';

// not yet used
export const ContactPage: FC<IBasePageComponent> = () => {
  return (
    <div
      className="container mx-auto  w-full h-max backdrop-blur-xs justify-center min-h-full text-white"
      data-testid="contact-container"
    >
      Contact Page
    </div>
  );
};

export default ContactPage;
