import { FC, useMemo } from 'react';
import { ITrainingProgram } from '@/common/api/trainings';
import { TrainingType } from '@/common/enums';
import { TrainingProgramTile } from './TrainingProgramTile';

interface ITrainingProgramsList {
  trainingPrograms: ITrainingProgram[];
}

export const TrainingProgramsList: FC<ITrainingProgramsList> = ({ trainingPrograms }) => {
  const [adtProgram, otherTrainingPrograms] = useMemo(() => {
    const adtProgram = trainingPrograms.find(
      ({ training_type }) => training_type === TrainingType.ADT,
    );
    const otherPrograms = trainingPrograms.filter(
      ({ training_type }) => training_type !== TrainingType.ADT,
    );
    return [adtProgram, otherPrograms];
  }, [trainingPrograms]);

  return (
    <>
      {adtProgram ? <TrainingProgramTile trainingProgram={adtProgram} /> : null}
      <div className="mt-4 flex flex-wrap items-stretch gap-4">
        {otherTrainingPrograms.map(trainingProgram => (
          <TrainingProgramTile
            trainingProgram={trainingProgram}
            key={trainingProgram.id}
            className="flex-grow basis-[calc(50%-0.5rem)]"
          />
        ))}
      </div>
    </>
  );
};
