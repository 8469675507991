import { User } from './api/users';
import { useQuery } from '@tanstack/react-query';
import { apiGet } from './axios';

const initialUserInfo: User = {
  email: '',
  first_name: '',
  last_name: '',
  full_name: '',
};

const getMyInfo = async (): Promise<User> => {
  const { data } = await apiGet<User>('/accounts/me/');
  if (data && !data.full_name) {
    data.full_name = `${data.first_name} ${data.last_name}`.trim();
  }
  return data;
};

const useUser = () => {
  const { data: userInfo } = useQuery({
    queryKey: ['current-user-info'],
    queryFn: getMyInfo,
  });

  return userInfo ?? initialUserInfo;
};

export { useUser };
