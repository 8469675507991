import { apiGet } from '../axios';
import { ITrainingProgram } from './trainings';

interface IPageParagraph {
  id: number;
  content: string;
}

interface IPageContent {
  id: number;
  page_title: string;
  paragraphs: IPageParagraph[];
  background_image_url: string | undefined;
}

interface ITrainingProgramParagraph {
  id: string;
  title: string | null;
  description: string;
}

interface ITrainingProgramContent {
  id: number;
  training_program: ITrainingProgram;
  description: string;
  background_image_url: string | undefined;
  paragraphs: ITrainingProgramParagraph[];
}

const getPageContent = (pageSlug: string) => {
  const url = `/cms/pages/${pageSlug}/`;
  return apiGet<IPageContent>(url);
};

const getTrainingProgramDetailsContent = (programId: string) => {
  return apiGet<ITrainingProgramContent>(`/cms/training-program/${programId}/`);
};

export { getPageContent, getTrainingProgramDetailsContent, IPageContent, ITrainingProgramContent };
