import type { PropsWithChildren } from 'react';
import { AuthProvider as Auth, TAuthConfig } from '@unbooking/ui-auth';
import { axiosInstance } from '@/common/axios';
import { AZURE_CLIENT_ID, AZURE_EXTRA_SCOPE } from '@/common/auth';

const azureScope = `${AZURE_EXTRA_SCOPE} openid profile offline_access`;
const googleScope = 'email profile openid';

export const authConfig: TAuthConfig = {
  clientId: AZURE_CLIENT_ID,
  refreshTokenAddScope: true,
  alwaysUseLoginHint: true,
  authorizationEndpoint: import.meta.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT || '',
  autoAuthorize: false,
  unbhPermissionsEndpoint: '',
  tokenEndpoint: import.meta.env.REACT_APP_AUTH_TOKEN_ENDPOINT || '',
  tokenBearerSource: 'access_token',
  tokenExpirationSource: 'access_token',
  redirectUri: import.meta.env.REACT_APP_AUTH_REDIRECT_URI || '',
  logoutEndpoint: import.meta.env.REACT_APP_AUTH_LOGOUT_ENDPOINT || '',
  logoutUri: import.meta.env.REACT_APP_AUTH_LOGOUT_URI || '',
  scope: azureScope,
  configurations: {
    google: {
      authorizationEndpoint: import.meta.env.REACT_APP_GOOGLE_AUTHORIZATION_ENDPOINT || '',
      tokenBearerSource: 'id_token',
      tokenExpirationSource: 'id_token',
      tokenEndpoint: import.meta.env.REACT_APP_GOOGLE_TOKEN_ENDPOINT || '',
      clientId: import.meta.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '',
      clientSecret: import.meta.env.REACT_APP_GOOGLE_AUTH_CLIENT_SECRET || '',
      logoutUri: '/login/',
      scope: googleScope,
    },
  },
};

export function AuthProvider(props: PropsWithChildren) {
  return (
    <Auth authConfig={authConfig} axiosInstance={axiosInstance}>
      {props.children}
    </Auth>
  );
}

export default AuthProvider;
