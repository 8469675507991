import { Feature } from '../../components/impact/ImpactMap';
import { apiGet } from '../axios';

interface ICountryTrainings {
  id: string;
  name: string;
  code: string;
  num_of_trainings: number;
  num_of_drivers: number;
  num_of_countries?: undefined;
}

interface IGlobalTrainingStats {
  num_of_trainings: number;
  num_of_drivers: number;
  num_of_countries: number;
}

interface ICountryTrainingsResponse {
  countries: ICountryTrainings[];
  global_stats: IGlobalTrainingStats;
}

interface FeatureResponse {
  type: string;
  features: Feature[];
}

const getGeoJson = async () => {
  const url = 'https://engineering.unbooking.org/countries.geo.json';
  const { data } = await apiGet<FeatureResponse>(url);
  return data.features;
};

const getCountryTrainings = async () => {
  const url = '/countries/country-trainings/';
  const { data } = await apiGet<ICountryTrainingsResponse>(url);
  return data;
};

export { ICountryTrainings, IGlobalTrainingStats, getGeoJson, getCountryTrainings };
