export const HomeIcon = () => (
  <svg
    className="inline-block"
    width="21"
    height="21"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_487_1538)">
      <path
        d="M9.34442 1.24523C9.24457 1.16732 9.12156 1.125 8.99491 1.125C8.86826 1.125 8.74525 1.16732 8.6454 1.24523L0.5625 7.54838L1.26152 8.43246L2.25 7.66173V14.625C2.25061 14.9232 2.36933 15.2089 2.58018 15.4198C2.79102 15.6306 3.07682 15.7494 3.375 15.75H14.625C14.9232 15.7494 15.209 15.6307 15.4199 15.4199C15.6307 15.209 15.7494 14.9232 15.75 14.625V7.66684L16.7385 8.43747L17.4375 7.55333L9.34442 1.24523ZM10.125 14.625H7.875V10.125H10.125V14.625ZM11.25 14.625V10.125C11.2497 9.82671 11.131 9.54076 10.9201 9.32985C10.7092 9.11895 10.4233 9.00031 10.125 8.99997H7.875C7.57672 9.00027 7.29075 9.11889 7.07983 9.3298C6.86892 9.54072 6.7503 9.82669 6.75 10.125V14.625H3.375V6.78456L9 2.40269L14.625 6.79047V14.625H11.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_487_1538">
        <rect width="18" height="18" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default HomeIcon;
