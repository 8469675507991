import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useParams } from 'react-router';
import { getTrainingProgramDetailsContent } from '@/common/api/cms';
import { TrainingProgramDescription } from '@/components/trainings-programs/TrainingProgramDescription';
import { getBgImageStyle } from '@/common/styles';

const TrainingProgramDetailPage: FC = () => {
  const { id } = useParams() as { id: string };
  const { isError, data: response } = useQuery({
    queryKey: ['training-program', id],
    queryFn: () => getTrainingProgramDetailsContent(id),
  });

  if (isError) {
    return (
      <p className="container">There was an unexpected error while loading the page content</p>
    );
  }
  return response ? (
    <div
      style={getBgImageStyle(response.data.background_image_url)}
      className="flex bg-no-repeat bg-cover w-full"
    >
      <div
        className="w-full overflow-y-auto flex flex-col items-stretch"
        data-testid="training-details-container"
      >
        <div className="pt-44 h-max">
          <TrainingProgramDescription trainingProgramContent={response.data} />
        </div>
        <div className="bg-training-details h-full" />
      </div>
    </div>
  ) : (
    <div />
  );
};

export default TrainingProgramDetailPage;
