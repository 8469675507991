export const CheckedIcon = () => (
  <svg width="21" height="21" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 0.25C7.76942 0.25 6.07769 0.763179 4.63876 1.72464C3.19983 2.6861 2.07832 4.05267 1.41606 5.65152C0.753791 7.25037 0.580512 9.00971 0.918133 10.707C1.25575 12.4044 2.08911 13.9635 3.31282 15.1872C4.53653 16.4109 6.09563 17.2442 7.79296 17.5819C9.4903 17.9195 11.2496 17.7462 12.8485 17.0839C14.4473 16.4217 15.8139 15.3002 16.7754 13.8612C17.7368 12.4223 18.25 10.7306 18.25 9C18.25 6.67936 17.3281 4.45376 15.6872 2.81282C14.0462 1.17187 11.8206 0.25 9.5 0.25ZM8.25 12.4942L5.125 9.36925L6.11913 8.375L8.25 10.5058L12.8813 5.875L13.8786 6.86619L8.25 12.4942Z"
      fill="#42BE65"
    />
  </svg>
);

export default CheckedIcon;
