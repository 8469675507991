import { Footer } from '@unbooking/ui-kit';
import { FC } from 'react';

const RoadSafetyFooter: FC = () => (
  <Footer
    appVersion={import.meta.env.NPM_PACKAGE_VERSION}
    termsOfUseLink="https://cdn.wfp.org/legal/terms/"
    privacyPolicyLink="https://unbooking.org/en/public/privacy-policy/"
  />
);

export default RoadSafetyFooter;
