import { FC } from 'react';
import { useParams } from 'react-router';
import TrainingRequestDetail from '../../components/digital-office/training-requests/details/TrainingRequestDetail';
import { IPageContent } from '@/common/api/cms';
import { getBgImageStyle } from '@/common/styles';

interface ITrainingRequestPage {
  content: IPageContent;
}

const TrainingRequestPage: FC<ITrainingRequestPage> = ({ content }) => {
  const { trainingRequestId } = useParams() as { trainingRequestId: string };
  return (
    <div
      style={getBgImageStyle(content.background_image_url)}
      className="flex flex-1 bg-no-repeat bg-cover bg-center"
    >
      <TrainingRequestDetail requestId={trainingRequestId} />
    </div>
  );
};

export default TrainingRequestPage;
