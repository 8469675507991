import { FC } from 'react';

interface ITrainerIcon {
  size?: string;
  className?: string;
}

export const TrainerIcon: FC<ITrainerIcon> = ({ size = '21', className }) => (
  <svg
    data-testid="trainer-icon"
    width={size}
    height={size}
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_751_293)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2362 3.52666C11.7223 3.18328 11.1181 3 10.5 3C9.6712 3 8.87634 3.32924 8.29029 3.91529C7.70424 4.50134 7.375 5.2962 7.375 6.125C7.375 6.74307 7.55828 7.34725 7.90166 7.86116C8.24504 8.37506 8.7331 8.7756 9.30412 9.01212C9.87514 9.24865 10.5035 9.31053 11.1097 9.18995C11.7159 9.06938 12.2727 8.77175 12.7097 8.33471C13.1467 7.89767 13.4444 7.34085 13.565 6.73466C13.6855 6.12847 13.6236 5.50013 13.3871 4.92911C13.1506 4.3581 12.7501 3.87004 12.2362 3.52666ZM8.06938 2.48732C8.78885 2.00659 9.63471 1.75 10.5 1.75C11.6603 1.75 12.7731 2.21094 13.5936 3.03141C14.4141 3.85188 14.875 4.96468 14.875 6.125C14.875 6.99029 14.6184 7.83615 14.1377 8.55562C13.6569 9.27508 12.9737 9.83584 12.1742 10.167C11.3748 10.4981 10.4951 10.5847 9.64648 10.4159C8.79781 10.2471 8.01826 9.83045 7.40641 9.21859C6.79456 8.60674 6.37788 7.82719 6.20907 6.97852C6.04026 6.12985 6.1269 5.25019 6.45803 4.45076C6.78916 3.65133 7.34992 2.96805 8.06938 2.48732ZM15.5 19.25H16.75V16.125C16.75 14.9647 16.2891 13.8519 15.4686 13.0314C14.6481 12.2109 13.5353 11.75 12.375 11.75H8.625C7.46468 11.75 6.35188 12.2109 5.53141 13.0314C4.71094 13.8519 4.25 14.9647 4.25 16.125V19.25H5.5V16.125C5.5 15.2962 5.82924 14.5013 6.41529 13.9153C7.00134 13.3292 7.7962 13 8.625 13H12.375C12.7854 13 13.1917 13.0808 13.5709 13.2379C13.95 13.3949 14.2945 13.6251 14.5847 13.9153C14.8749 14.2055 15.1051 14.55 15.2621 14.9291C15.4192 15.3083 15.5 15.7146 15.5 16.125V19.25Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_751_293">
        <rect width="20" height="20" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default TrainerIcon;
