import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import RequestTrainingForm from '../../components/forms/RequestTrainingForm/RequestTrainingForm';
import { IBasePageComponent } from '@/common/interfaces';
import { getBgImageStyle } from '@/common/styles';
import { getTrainingProgramDetailsContent } from '@/common/api/cms';
import { ITrainingProgram } from '@/common/api/trainings';
import { Spinner } from '@components';

export const RequestTrainingFormPage: FC<IBasePageComponent> = ({ content }) => {
  const params = new URLSearchParams(window.location.search);
  const programId = params.get('program');

  const { isLoading, data: trainingProgram = {} as ITrainingProgram } = useQuery({
    queryKey: ['training-program-detail', programId],
    queryFn: async () => {
      if (!programId) return null;
      const { data } = await getTrainingProgramDetailsContent(programId);
      return data.training_program;
    },
  });

  return (
    <div
      style={getBgImageStyle(content.background_image_url)}
      className="bg-no-repeat bg-cover w-full overflow-y-auto bg-fixed"
    >
      <div className="flex justify-center items-start py-10">
        <div
          className="flex flex-col bg-training-details overflow-y-auto w-full max-w-[1500px] mx-4"
          data-testid="request-training-form-container"
        >
          {isLoading ? (
            <Spinner fullScreen />
          ) : (
            <RequestTrainingForm trainingProgram={trainingProgram} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestTrainingFormPage;
