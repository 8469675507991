export const PATHS = {
  home: '/',
  impact: '/impact',
  trainings: '/trainings',
  instructors: '/instructors',
  contact: '/contact',
  trainingDetails: '/training-programs/:id',
  requestTraining: '/request_training',
  requestTrainingSuccess: '/request_training/success/:requestId',
  whoAreWe: '/who-are-we',
  login: '/login',
  digitalOffice: '/digital-office',
  digitalOfficeTrainingDetails: '/digital-office/training-request/:trainingRequestId',
  digitalOfficeTrainings: '/digital-office/training-requests',
};
