import { Button } from '@unbooking/ui-kit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  IRequestTrainingFormData,
  IRequestTrainingFormStepProps,
  RequestTrainingFormSubmission,
  RequestTrainingStep,
} from '../common/interfaces';
import BaseStep from './components/BaseStep';
import FormSummary from './components/FormSummary';
import { ICreatedRequest, postTrainingRequest } from '@/common/api/trainings';
import { handleError } from '@/common/handleError';
import { SendIcon } from '@icons';
import { Spinner } from '@components';

const STEP = RequestTrainingStep.CONFIRM;

interface IConfirmButton {
  formData: IRequestTrainingFormData;
}

export const ConfirmButton: FC<IConfirmButton> = ({ formData }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const parseFormForSubmission = (formData: RequestTrainingFormSubmission) => {
    formData['program'] = formData.program.id;
    const focalPoint = formData.focal_point;
    const focalPointEmpty =
      focalPoint === null || Object.values(focalPoint).every(value => value === '');
    if (focalPointEmpty) formData.focal_point = null;
  };

  const createFormSubmission = (form: IRequestTrainingFormData) => {
    let flattenedForm: Record<string, any> = {};
    for (const formStep of Object.values(form)) {
      flattenedForm = { ...flattenedForm, ...formStep };
    }
    const submissionForm = flattenedForm as RequestTrainingFormSubmission;
    parseFormForSubmission(submissionForm);
    return submissionForm;
  };

  const redirectToSuccess = (createdRequest: ICreatedRequest) => {
    const path = `/request_training/success/${createdRequest.request_id}`;
    navigate(path);
  };

  const sendForm = async () => {
    setIsLoading(true);
    const submissionForm = createFormSubmission(formData);
    try {
      const response = await postTrainingRequest(submissionForm);
      if (response.status === 201) redirectToSuccess(response.data);
    } catch (error) {
      handleError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const sendIcon = isLoading ? <Spinner width={20} height={20} color="white" /> : <SendIcon />;

  return (
    <div className="flex self-start mt-10">
      <Button
        label="Submit your request"
        onClick={sendForm}
        disabled={isLoading}
        endIcon={sendIcon}
      />
    </div>
  );
};

export const ConfirmStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  goToNextStep,
  goToPreviousStep,
  setSelectedStep,
}) => {
  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextButton={<ConfirmButton formData={formData} />}
    >
      <div className="flex flex-col w-full gap-4 overflow-auto max-h-[40vh]">
        <div className="text-white font-thin">Here is a quick summary of your request:</div>
        <FormSummary formData={formData} setSelectedStep={setSelectedStep} />
        <div className="text-white">
          Submit to inform the Road Safety Academy team about your interest. You will be contacted
          once your request has been reviewed.
        </div>
      </div>
    </BaseStep>
  );
};

export default ConfirmStep;
