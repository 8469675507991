import { baseUrl } from './axios';

const getFullImageUrl = (imageUrl: string) => {
  return imageUrl.includes('http') ? imageUrl : `${baseUrl}${imageUrl}`;
};

// required for tailwind to load the bg images dynamically
const getBgImageStyle = (imageUrl: string | undefined) => {
  if (!imageUrl) return {};
  const fullImageUrl = getFullImageUrl(imageUrl);
  const backgroundImage = `url('${fullImageUrl}')`;
  return { backgroundImage };
};

export { getFullImageUrl, getBgImageStyle };
