import { FC } from 'react';
import ListSelectInput from './ListSelectInput';

interface IYesNoInput {
  value: boolean | null;
  onChange: (value: boolean) => void;
  light?: boolean;
}

export const YesNoInput: FC<IYesNoInput> = ({ value, onChange, light = false }) => {
  return (
    <ListSelectInput
      onChange={value => onChange(value as boolean)}
      value={value}
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]}
      light={light}
    />
  );
};

export default YesNoInput;
