import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

const prefix = window.location.host.split('.')[0].toLowerCase();
const ENV = prefix === 'roadsafetyacademy' ? 'production' : prefix;
const ENABLE_SENTRY = ['dev', 'qa', 'production'].includes(ENV);

if (ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://94aeb4e94aa83903f09b2cbfac2b48bf@o274918.ingest.us.sentry.io/4507287987945472',
    environment: ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.001,
  });
}
