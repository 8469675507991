import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Header } from '@unbooking/ui-kit';
import { useAuth } from '@unbooking/ui-auth';
import {
  CalendarOptionIcon,
  GraduationCapIcon,
  HomeIcon,
  ImpactIcon,
  InfoIcon,
  TrainerIcon,
} from '@icons';
import { WFPIcon } from '../../assets/img';
import { getDOPermissionData } from '@/common/api/digitalOffice';
import { PATHS } from '@/common/path';
import { useUser } from '@/common/hooks';

export type HeaderMenuType = 'default' | 'none' | 'digital-office';

const defaultNavItems = [
  {
    name: 'Home',
    path: PATHS.home,
    icon: <HomeIcon />,
  },
  {
    name: 'Who we are',
    path: PATHS.whoAreWe,
    icon: <InfoIcon />,
  },
  {
    name: 'Global impact',
    path: PATHS.impact,
    icon: <ImpactIcon />,
  },
  {
    name: 'Our trainings',
    path: PATHS.trainings,
    icon: <GraduationCapIcon />,
  },
  {
    name: 'Our trainers',
    path: PATHS.instructors,
    icon: <TrainerIcon className="fill-current" />,
  },
  {
    name: 'Request a training',
    path: PATHS.requestTraining,
    icon: <CalendarOptionIcon />,
    className: 'bg-sky-500 rounded-sm',
  },
  // {
  //   name: 'Contact us',
  //   path: PATHS.contact,
  //   icon: <CalendarOptionIcon />,
  // },
];

const digitalOfficeNavItems = [
  {
    name: 'Home',
    path: PATHS.digitalOffice,
    icon: <HomeIcon />,
  },
  {
    name: 'Requests list',
    path: PATHS.digitalOfficeTrainings,
    icon: <InfoIcon />,
  },
];

interface IRoadSafetyHeader {
  menu?: HeaderMenuType;
}

export const RoadSafetyHeader: FC<IRoadSafetyHeader> = ({ menu = 'default' }) => {
  const { logout } = useAuth();
  const user = useUser();

  const { data: isDO } = useQuery({
    queryKey: ['digital-office-perms'],
    queryFn: async () => {
      const res = await getDOPermissionData();
      return res.is_do;
    },
  });
  const doLink = isDO ? '/digital-office' : '';
  const navItems =
    menu === 'none' ? [] : menu === 'digital-office' ? digitalOfficeNavItems : defaultNavItems;

  return (
    <Header
      username={user.full_name || user.email}
      navItems={navItems}
      logoImg={WFPIcon}
      logout={logout}
      lockLink={doLink}
    />
  );
};

export default RoadSafetyHeader;
