import { FC } from 'react';
import { getFullImageUrl } from '@/common/styles';
import { IInstructorDescription } from '@/pages/InstructorsPage/InstructorsPage';
import { Tip } from '@components';

export interface IInstructorRow {
  instructor: IInstructorDescription;
}

export const InstructorRow: FC<IInstructorRow> = ({ instructor }) => {
  const imageUrl = instructor.image_url ? getFullImageUrl(instructor.image_url) : undefined;
  const instructorData = instructor.instructor ?? {};
  const trainingPrograms = instructorData.training_programs;
  const languages = instructorData.languages;

  return (
    <div className="flex flex-row justify-between items-start gap-12">
      <div className="min-w-[320px]">
        <img src={imageUrl} alt="Trainer" width="320px" />
      </div>
      <div className="flex flex-col justify-start items-start">
        <div className="text-2xl" dangerouslySetInnerHTML={{ __html: instructorData.name }} />
        <div
          className="text-default font-light mt-2 text-left max-w-[90%] leading-[1.8]"
          dangerouslySetInnerHTML={{ __html: instructor.description }}
        />
        <div className="flex gap-8 mt-5">
          {trainingPrograms?.length ? (
            <div className="flex gap-4">
              {trainingPrograms
                .filter(({ icon }) => icon)
                .map(program => (
                  <Tip key={program.id} text={program.title}>
                    <img
                      src={program.icon}
                      alt={program.title}
                      className="h-[30px] object-contain"
                    />
                  </Tip>
                ))}
            </div>
          ) : null}

          {languages?.length ? (
            <div className="flex gap-2 items-center">
              <label className="uppercase text-xs">Languages:</label>
              {languages.map(language => (
                <div
                  key={language}
                  className="bg-white text-black text-sm px-2 py-1 font-bold rounded"
                >
                  {language.toUpperCase()}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

interface IInstructorsList {
  instructors: IInstructorDescription[];
}

export const InstructorsList: FC<IInstructorsList> = ({ instructors }) => {
  return (
    <div className="flex flex-col justify-start gap-12 mt-6">
      {instructors.map(instructor => (
        <InstructorRow instructor={instructor} key={instructor.id} />
      ))}
    </div>
  );
};
