import { FC } from 'react';
import { IBasePageComponent } from '@/common/interfaces';
import ImpactMap from '../../components/impact/ImpactMap';

export const ImpactPage: FC<IBasePageComponent> = ({ content }) => {
  return (
    <div
      className="mx-auto w-full h-max backdrop-blur-xs justify-center min-h-full text-white"
      data-testid="impact-container"
    >
      <ImpactMap />
    </div>
  );
};

export default ImpactPage;
