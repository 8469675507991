import { FC } from 'react';

export const InfoIcon: FC<{ size?: string }> = ({ size = '21' }) => (
  <svg
    className="inline-block"
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_487_1549)">
      <path
        d="M9.5625 12.375V7.875H7.3125V9H8.4375V12.375H6.75V13.5H11.25V12.375H9.5625Z"
        fill="currentColor"
      />
      <path
        d="M9 4.5C8.83312 4.5 8.66999 4.54948 8.53124 4.6422C8.39248 4.73491 8.28434 4.86669 8.22048 5.02086C8.15662 5.17504 8.13991 5.34469 8.17246 5.50836C8.20502 5.67203 8.28538 5.82237 8.40338 5.94037C8.52138 6.05837 8.67172 6.13873 8.83539 6.17129C8.99906 6.20384 9.16871 6.18713 9.32289 6.12327C9.47706 6.05941 9.60884 5.95127 9.70155 5.81251C9.79427 5.67376 9.84375 5.51063 9.84375 5.34375C9.84375 5.11997 9.75486 4.90536 9.59662 4.74713C9.43839 4.58889 9.22378 4.5 9 4.5Z"
        fill="currentColor"
      />
      <path
        d="M9 16.875C7.44248 16.875 5.91992 16.4131 4.62489 15.5478C3.32985 14.6825 2.32049 13.4526 1.72445 12.0136C1.12841 10.5747 0.972461 8.99127 1.27632 7.46367C1.58018 5.93607 2.3302 4.53288 3.43154 3.43154C4.53288 2.3302 5.93607 1.58018 7.46367 1.27632C8.99127 0.972461 10.5747 1.12841 12.0136 1.72445C13.4526 2.32049 14.6825 3.32985 15.5478 4.62489C16.4131 5.91992 16.875 7.44248 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 2.25C7.66498 2.25 6.35994 2.64588 5.2499 3.38758C4.13987 4.12928 3.27471 5.18349 2.76382 6.41689C2.25293 7.65029 2.11925 9.00749 2.3797 10.3169C2.64015 11.6262 3.28303 12.829 4.22703 13.773C5.17104 14.717 6.37377 15.3599 7.68314 15.6203C8.99252 15.8808 10.3497 15.7471 11.5831 15.2362C12.8165 14.7253 13.8707 13.8601 14.6124 12.7501C15.3541 11.6401 15.75 10.335 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_487_1549">
        <rect width="18" height="18" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default InfoIcon;
