import dayjs from 'dayjs';
import { RANGE_SEPARATOR } from '@/common/constants';

export const capitalize = (value: string | null | undefined) => {
  if (!value) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const pluralize = (value: number, singular = '', plural = 's') => {
  return value === 1 ? singular : plural;
};

export const rangeDateDisplay = (startDate: string, endDate: string) => {
  return [dayjs(startDate).toDate().toISOString(), dayjs(endDate).toDate().toISOString()].join(
    RANGE_SEPARATOR,
  );
};
