import { InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import { FC, useContext, useEffect } from 'react';
import {
  IContactPerson,
  IRequestTrainingFormStepProps,
  ISupervisor,
  RequestTrainingStep,
} from '../common/interfaces';
import BaseStep from './components/BaseStep';
import { RequestTrainingFormContext } from '../common/formContext';
import {
  nonEmptyValidation,
  nonEmptyEmailValidation,
  nonEmptyPhoneValidation,
  FieldValidationWarning,
  emailValidation,
  phoneValidation,
} from '../common/FieldValidationWarning';
import { RequiredMark, RequiredUnderline } from '@/components/common';

const STEP = RequestTrainingStep.COMMUNICATIONS;

export const CommunicationsStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  updateFormData,
  goToNextStep,
  goToPreviousStep,
  updateStepCompleted,
}) => {
  const { formContext } = useContext(RequestTrainingFormContext);
  const formStep = formData[STEP];

  const updateFocalPoint = (field: keyof IContactPerson, value: string) => {
    updateFormData('focal_point', { ...formStep.focal_point, [field]: value });
  };
  const updateSupervisor = (field: keyof ISupervisor, value: string) => {
    updateFormData('supervisor', { ...formStep.supervisor, [field]: value });
  };
  const supervisorValidations = {
    first_name: nonEmptyValidation(formStep.supervisor.first_name),
    last_name: nonEmptyValidation(formStep.supervisor.last_name),
    email: emailValidation(formStep.supervisor.email),
    // phone_number: nonEmptyPhoneValidation(formStep.supervisor.phone_number),
    role: nonEmptyValidation(formStep.supervisor.role),
  };
  const focalPointValidations = {
    first_name: nonEmptyValidation(formStep.focal_point?.first_name),
    last_name: nonEmptyValidation(formStep.focal_point?.last_name),

    phone_number: phoneValidation(formStep.focal_point?.phone_number),
    email: emailValidation(formStep.focal_point?.email),
  };

  const validators = [
    ...Object.values(supervisorValidations),
    ...Object.values(focalPointValidations),
  ];
  const nextStepEnabled = validators.every(validator => validator === null);

  const supervisorOptions = formContext ? formContext.supervisor_roles : [];

  useEffect(() => {
    updateStepCompleted(STEP, nextStepEnabled);
  }, [nextStepEnabled]);

  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextStepEnabled={nextStepEnabled}
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col items-stretch gap-6">
          <div className="text-white">
            Please, provide the details of the head of office/head of department/fleet manager
            <RequiredMark />
          </div>
          <div className="flex flex-col">
            <InputDropdown
              value={formStep.supervisor.role as string}
              onChange={e => updateSupervisor('role', e.target.value)}
              items={supervisorOptions as InputDropdownItem[]}
              name="supervisor-role"
              placeholder="Select the role"
            />
            {supervisorValidations.role && <RequiredUnderline />}
            {/* <FieldValidationWarning result={supervisorValidations.role} /> */}
          </div>
          <div className="flex flex-col">
            <InputText
              value={formStep.supervisor.first_name}
              onChange={e => updateSupervisor('first_name', e.target.value)}
              name="supervisor-first-name"
              placeholder="First name"
            />
            {supervisorValidations.first_name && <RequiredUnderline />}
            {/* <FieldValidationWarning result={supervisorValidations.first_name} /> */}
          </div>
          <div className="flex flex-col">
            <InputText
              value={formStep.supervisor.last_name}
              onChange={e => updateSupervisor('last_name', e.target.value)}
              name="supervisor-last-name"
              placeholder="Last name"
            />
            {supervisorValidations.last_name && <RequiredUnderline />}
            {/* <FieldValidationWarning result={supervisorValidations.last_name} /> */}
          </div>
          {/*<div className="flex flex-col">*/}
          {/*  <InputText*/}
          {/*    value={formStep.supervisor.phone_number}*/}
          {/*    onChange={e => updateSupervisor('phone_number', e.target.value)}*/}
          {/*    name="supervisor-phone-number"*/}
          {/*    placeholder="Phone Number"*/}
          {/*  />*/}
          {/*  <FieldValidationWarning result={supervisorValidations.phone_number} />*/}
          {/*</div>*/}
          <div className="flex flex-col">
            <InputText
              value={formStep.supervisor.email}
              onChange={e => updateSupervisor('email', e.target.value)}
              name="supervisor-email"
              placeholder="Email"
            />
            {supervisorValidations.email && <RequiredUnderline />}
            <FieldValidationWarning result={supervisorValidations.email} />
          </div>
        </div>
        <div className="flex flex-col items-stretch gap-6">
          <div className="text-white">
            Name the primary focal point for all driver training communication and coordination and
            provide us with their phone number
            <RequiredMark />
          </div>
          <div className="flex flex-col">
            <InputText
              value={formStep.focal_point?.first_name}
              onChange={e => updateFocalPoint('first_name', e.target.value)}
              name="focal-point-first-name"
              placeholder="First name"
            />
            {focalPointValidations.first_name && <RequiredUnderline />}
            {/* <FieldValidationWarning result={focalPointValidations.first_name} /> */}
          </div>
          <div className="flex flex-col">
            <InputText
              value={formStep.focal_point?.last_name}
              onChange={e => updateFocalPoint('last_name', e.target.value)}
              name="focal-point-last-name"
              placeholder="Last name"
            />
            {focalPointValidations.last_name && <RequiredUnderline />}
            {/* <FieldValidationWarning result={focalPointValidations.last_name} /> */}
          </div>
          <div className="flex flex-col">
            <InputText
              value={formStep.focal_point?.phone_number}
              onChange={e => updateFocalPoint('phone_number', e.target.value)}
              name="focal-point-phone-number"
              placeholder="Phone Number"
            />
            {focalPointValidations.phone_number && <RequiredUnderline />}
            <FieldValidationWarning result={focalPointValidations.phone_number} />
          </div>
          <div className="flex flex-col">
            <InputText
              value={formStep.focal_point?.email}
              onChange={e => updateFocalPoint('email', e.target.value)}
              name="focal-point-email"
              placeholder="Email"
            />
            {focalPointValidations.email && <RequiredUnderline />}
            <FieldValidationWarning result={focalPointValidations.email} />
          </div>
        </div>
      </div>
    </BaseStep>
  );
};

export default CommunicationsStep;
