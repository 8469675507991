export const SendIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.183535 0.112318C0.258182 0.0513274 0.348869 0.0132152 0.444674 0.00257017C0.54048 -0.0080749 0.63732 0.00920101 0.723535 0.0523176L15.7235 7.55232C15.8067 7.59379 15.8767 7.65763 15.9257 7.73668C15.9746 7.81573 16.0005 7.90685 16.0005 7.99982C16.0005 8.09278 15.9746 8.18391 15.9257 8.26296C15.8767 8.342 15.8067 8.40585 15.7235 8.44732L0.723535 15.9473C0.637407 15.9905 0.540652 16.0078 0.444904 15.9973C0.349156 15.9867 0.258492 15.9487 0.183812 15.8879C0.109132 15.8271 0.0536173 15.7459 0.023942 15.6543C-0.00573323 15.5626 -0.0083054 15.4644 0.0165351 15.3713L1.98254 8.00032L0.0165351 0.629318C-0.00842482 0.536214 -0.005941 0.437881 0.0236876 0.346156C0.0533162 0.254432 0.108827 0.173226 0.183535 0.112318ZM2.88353 8.50032L1.26954 14.5563L14.3815 8.00032L1.26854 1.44432L2.88353 7.50032H9.49954C9.63214 7.50032 9.75932 7.553 9.85309 7.64676C9.94686 7.74053 9.99954 7.86771 9.99954 8.00032C9.99954 8.13293 9.94686 8.2601 9.85309 8.35387C9.75932 8.44764 9.63214 8.50032 9.49954 8.50032H2.88353Z"
      fill="currentColor"
    />
  </svg>
);

export default SendIcon;
