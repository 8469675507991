export const CommunicationIcon = ({ size = '21' }) => {
  return (
    <svg
      data-testid="communication-icon"
      width={size}
      height={size}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.5 3C20.2956 3 21.0587 3.31607 21.6213 3.87868C22.1839 4.44129 22.5 5.20435 22.5 6V16C22.5 16.7956 22.1839 17.5587 21.6213 18.1213C21.0587 18.6839 20.2956 19 19.5 19H7.833L4.5 21.5C3.676 22.118 2.5 21.53 2.5 20.5V6C2.5 5.20435 2.81607 4.44129 3.37868 3.87868C3.94129 3.31607 4.70435 3 5.5 3H19.5ZM19.5 5H5.5C5.23478 5 4.98043 5.10536 4.79289 5.29289C4.60536 5.48043 4.5 5.73478 4.5 6V19L6.633 17.4C6.97919 17.1404 7.40026 17 7.833 17H19.5C19.7652 17 20.0196 16.8946 20.2071 16.7071C20.3946 16.5196 20.5 16.2652 20.5 16V6C20.5 5.73478 20.3946 5.48043 20.2071 5.29289C20.0196 5.10536 19.7652 5 19.5 5ZM11.5 12C11.7549 12.0003 12 12.0979 12.1854 12.2728C12.3707 12.4478 12.4822 12.687 12.4972 12.9414C12.5121 13.1958 12.4293 13.4464 12.2657 13.6418C12.1021 13.8373 11.8701 13.9629 11.617 13.993L11.5 14H8.5C8.24512 13.9997 7.99997 13.9021 7.81463 13.7272C7.6293 13.5522 7.51777 13.313 7.50283 13.0586C7.48789 12.8042 7.57067 12.5536 7.73426 12.3582C7.89786 12.1627 8.1299 12.0371 8.383 12.007L8.5 12H11.5ZM16.5 8C16.7652 8 17.0196 8.10536 17.2071 8.29289C17.3946 8.48043 17.5 8.73478 17.5 9C17.5 9.26522 17.3946 9.51957 17.2071 9.70711C17.0196 9.89464 16.7652 10 16.5 10H8.5C8.23478 10 7.98043 9.89464 7.79289 9.70711C7.60536 9.51957 7.5 9.26522 7.5 9C7.5 8.73478 7.60536 8.48043 7.79289 8.29289C7.98043 8.10536 8.23478 8 8.5 8H16.5Z" />
    </svg>
  );
};

export default CommunicationIcon;
