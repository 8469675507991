export const AttendeesIcon = ({ size = '21' }) => (
  <svg
    data-testid="attendees-icon"
    width={size}
    height={size}
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_636_1608)">
      <path d="M16.75 9.25H15.5V10.5H16.75C17.2471 10.5005 17.7237 10.6983 18.0752 11.0498C18.4267 11.4013 18.6245 11.8779 18.625 12.375V14.875H19.875V12.375C19.874 11.5465 19.5445 10.7522 18.9586 10.1664C18.3728 9.58051 17.5785 9.25096 16.75 9.25Z" />
      <path d="M15.5 3C15.8708 3 16.2334 3.10997 16.5417 3.31599C16.85 3.52202 17.0904 3.81486 17.2323 4.15747C17.3742 4.50008 17.4113 4.87708 17.339 5.24079C17.2666 5.60451 17.0881 5.9386 16.8258 6.20083C16.5636 6.46305 16.2295 6.64163 15.8658 6.71397C15.5021 6.78632 15.1251 6.74919 14.7825 6.60727C14.4399 6.46536 14.147 6.22504 13.941 5.91669C13.735 5.60835 13.625 5.24584 13.625 4.875C13.625 4.37772 13.8225 3.90081 14.1742 3.54917C14.5258 3.19754 15.0027 3 15.5 3ZM15.5 1.75C14.8819 1.75 14.2777 1.93328 13.7638 2.27666C13.2499 2.62004 12.8494 3.1081 12.6129 3.67911C12.3764 4.25013 12.3145 4.87847 12.435 5.48466C12.5556 6.09085 12.8533 6.64767 13.2903 7.08471C13.7273 7.52175 14.2842 7.81938 14.8903 7.93995C15.4965 8.06053 16.1249 7.99865 16.6959 7.76212C17.2669 7.5256 17.755 7.12506 18.0983 6.61116C18.4417 6.09725 18.625 5.49307 18.625 4.875C18.625 4.0462 18.2958 3.25134 17.7097 2.66529C17.1237 2.07924 16.3288 1.75 15.5 1.75Z" />
      <path d="M14.875 19.25H13.625V18C13.6245 17.5029 13.4267 17.0263 13.0752 16.6748C12.7237 16.3233 12.2471 16.1255 11.75 16.125H9.25C8.75289 16.1255 8.27629 16.3233 7.92478 16.6748C7.57327 17.0263 7.37555 17.5029 7.375 18V19.25H6.125V18C6.12598 17.1715 6.45553 16.3772 7.04137 15.7914C7.62721 15.2055 8.4215 14.876 9.25 14.875H11.75C12.5785 14.876 13.3728 15.2055 13.9586 15.7914C14.5445 16.3772 14.874 17.1715 14.875 18V19.25Z" />
      <path d="M10.5 8.625C10.8708 8.625 11.2334 8.73497 11.5417 8.94099C11.85 9.14702 12.0904 9.43986 12.2323 9.78247C12.3742 10.1251 12.4113 10.5021 12.339 10.8658C12.2666 11.2295 12.0881 11.5636 11.8258 11.8258C11.5636 12.088 11.2295 12.2666 10.8658 12.339C10.5021 12.4113 10.1251 12.3742 9.78247 12.2323C9.43986 12.0904 9.14702 11.85 8.941 11.5417C8.73497 11.2334 8.625 10.8708 8.625 10.5C8.625 10.0027 8.82255 9.52581 9.17418 9.17417C9.52581 8.82254 10.0027 8.625 10.5 8.625ZM10.5 7.375C9.88194 7.375 9.27775 7.55828 8.76384 7.90166C8.24994 8.24504 7.8494 8.7331 7.61288 9.30411C7.37635 9.87513 7.31447 10.5035 7.43505 11.1097C7.55563 11.7158 7.85325 12.2727 8.29029 12.7097C8.72733 13.1467 9.28415 13.4444 9.89034 13.565C10.4965 13.6855 11.1249 13.6236 11.6959 13.3871C12.2669 13.1506 12.755 12.7501 13.0983 12.2362C13.4417 11.7223 13.625 11.1181 13.625 10.5C13.625 9.6712 13.2958 8.87634 12.7097 8.29029C12.1237 7.70424 11.3288 7.375 10.5 7.375Z" />
      <path d="M5.5 9.25H4.25C3.4215 9.25098 2.62721 9.58053 2.04137 10.1664C1.45553 10.7522 1.12598 11.5465 1.125 12.375V14.875H2.375V12.375C2.37555 11.8779 2.57327 11.4013 2.92478 11.0498C3.27629 10.6983 3.75289 10.5005 4.25 10.5H5.5V9.25Z" />
      <path d="M5.5 3C5.87084 3 6.23335 3.10997 6.5417 3.31599C6.85004 3.52202 7.09036 3.81486 7.23228 4.15747C7.37419 4.50008 7.41132 4.87708 7.33897 5.24079C7.26663 5.60451 7.08805 5.9386 6.82583 6.20083C6.5636 6.46305 6.22951 6.64163 5.8658 6.71397C5.50208 6.78632 5.12508 6.74919 4.78247 6.60727C4.43986 6.46536 4.14702 6.22504 3.941 5.91669C3.73497 5.60835 3.625 5.24584 3.625 4.875C3.625 4.37772 3.82255 3.90081 4.17418 3.54917C4.52581 3.19754 5.00272 3 5.5 3ZM5.5 1.75C4.88193 1.75 4.27775 1.93328 3.76384 2.27666C3.24994 2.62004 2.8494 3.1081 2.61288 3.67911C2.37635 4.25013 2.31447 4.87847 2.43505 5.48466C2.55563 6.09085 2.85325 6.64767 3.29029 7.08471C3.72733 7.52175 4.28415 7.81938 4.89034 7.93995C5.49654 8.06053 6.12487 7.99865 6.69589 7.76212C7.26691 7.5256 7.75496 7.12506 8.09834 6.61116C8.44172 6.09725 8.625 5.49307 8.625 4.875C8.625 4.0462 8.29576 3.25134 7.70971 2.66529C7.12366 2.07924 6.3288 1.75 5.5 1.75Z" />
    </g>
    <defs>
      <clipPath id="clip0_636_1608">
        <rect width="20" height="20" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default AttendeesIcon;
