import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, InputText } from '@unbooking/ui-kit';
import { useAuth } from '@unbooking/ui-auth';
import { AuthType } from '@unbooking/ui-auth/types';
import RoadSafetyFooter from '../../components/other/RoadSafetyFooter';
import SignInBg from '../../assets/img/sign_in_bg.png';
import Logo from '../../assets/img/RSA_LOGO.png';
import { GOOGLE_DOMAINS } from '@/common/auth';

export const SignInPage: FC = () => {
  const [signInEmail, setSignInEmail] = useState<string>('');
  const navigate = useNavigate();
  const { authorize, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) navigate('/');
  }, [isAuthenticated, navigate]);

  const initializeSignIn = () => {
    let authType: AuthType = 'azure';
    if (signInEmail) {
      const domain = signInEmail.split('@').pop();
      if (domain && GOOGLE_DOMAINS.includes(domain.toLowerCase())) {
        authType = 'google';
      }
    }
    authorize?.(authType, signInEmail);
  };

  return (
    <div data-testid="layout" className="flex flex-col h-screen w-full overflow-hidden">
      <div
        style={{ backgroundImage: `url(${SignInBg})` }}
        className="flex flex-1 bg-no-repeat bg-cover"
      >
        <div className="flex flex-col bg-training-details w-[500px] h-fit ml-40 mt-40 p-8">
          <div className="-mt-8 mb-2 -mx-8">
            <img src={Logo} className="h-32" alt="Road Safety Academy Logo" />
          </div>
          <div className="text-2xl text-white font-light mb-5">
            Log in to <b>Road Safety Academy</b>
          </div>
          <div className="text-white mb-1">Work email</div>
          <InputText
            value={signInEmail}
            onChange={e => setSignInEmail(e.target.value)}
            name="sign-in-email"
            placeholder="name@example.com"
          />
          <div className="mt-5 flex flex-col">
            <Button label="Log in" onClick={initializeSignIn} />
          </div>
        </div>
      </div>
      <RoadSafetyFooter />
    </div>
  );
};

export default SignInPage;
