export const QuestionMark = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM7.3905 7.485C6.9635 8.129 6.75 8.764 6.75 9.39C6.75 9.695 6.885 9.978 7.155 10.239C7.425 10.5 7.7555 10.63 8.1465 10.629C8.8115 10.629 9.263 10.256 9.501 9.51C9.753 8.797 10.061 8.257 10.425 7.89C10.789 7.524 11.356 7.341 12.126 7.341C12.784 7.341 13.3215 7.5225 13.7385 7.8855C14.1545 8.2495 14.3625 8.6955 14.3625 9.2235C14.3643 9.48839 14.2938 9.74875 14.1585 9.9765C14.0195 10.2077 13.85 10.4191 13.6545 10.605C13.3391 10.8938 13.0149 11.173 12.6825 11.442C12.1725 11.865 11.7665 12.23 11.4645 12.537C11.1645 12.845 10.923 13.2015 10.74 13.6065C10.257 15.474 12.765 15.624 13.344 14.2905C13.414 14.1625 13.5205 14.0205 13.6635 13.8645C13.8075 13.7095 13.9985 13.5295 14.2365 13.3245C14.8434 12.819 15.4405 12.302 16.0275 11.7735C16.3595 11.4675 16.646 11.1025 16.887 10.6785C17.136 10.2271 17.2612 9.71789 17.25 9.2025C17.25 8.4905 17.038 7.8305 16.614 7.2225C16.191 6.6135 15.591 6.1325 14.814 5.7795C14.037 5.4265 13.141 5.25 12.126 5.25C11.034 5.25 10.0785 5.4615 9.2595 5.8845C8.4405 6.3075 7.8175 6.842 7.3905 7.485ZM10.6005 18.105C10.6005 18.5028 10.7585 18.8844 11.0398 19.1657C11.3211 19.447 11.7027 19.605 12.1005 19.605C12.4983 19.605 12.8799 19.447 13.1612 19.1657C13.4425 18.8844 13.6005 18.5028 13.6005 18.105C13.6005 17.7072 13.4425 17.3256 13.1612 17.0443C12.8799 16.763 12.4983 16.605 12.1005 16.605C11.7027 16.605 11.3211 16.763 11.0398 17.0443C10.7585 17.3256 10.6005 17.7072 10.6005 18.105Z"
        fill="#BFEAFF"
      />
    </svg>
  );
};

export default QuestionMark;
