import { FC, PropsWithChildren, ReactNode } from 'react';
import { RequestTrainingStep } from '../../common/interfaces';
import StepTitle from './StepTitile';
import { PreviousStepButton, NextStepButton } from './StepButtons';
import { RequestTrainingFormSteps } from '../../RequestTrainingFormSteps';

interface IBaseStep extends PropsWithChildren {
  formStep: RequestTrainingStep;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  nextStepEnabled?: boolean;
  nextButton?: ReactNode;
}

export const BaseStep: FC<IBaseStep> = ({
  formStep,
  goToPreviousStep,
  goToNextStep,
  nextStepEnabled = true,
  nextButton,
  children,
}) => {
  const firstStep = RequestTrainingFormSteps[0].step;
  const showPreviousButton = formStep !== firstStep;

  return (
    <div className="flex flex-col grow items-start min-w-[500px]">
      <StepTitle formStep={formStep} />
      <div className="flex flex-col items-stretch w-full gap-10">{children}</div>
      <div className="flex items-center justify-between flex-row-reverse w-full">
        {nextButton || <NextStepButton goToNextStep={goToNextStep} disabled={!nextStepEnabled} />}
        {showPreviousButton && <PreviousStepButton goToPreviousStep={goToPreviousStep} />}
      </div>
    </div>
  );
};

export default BaseStep;
