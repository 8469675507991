import { FC } from 'react';
import { IPageContent } from '@/common/api/cms';
import { IPageWithContentComponent } from '@/common/interfaces';
import PageDescription from '../../components/cms/PageDescription';
import { TeamList } from '@/components/team-members/TeamList';
import { getBgImageStyle } from '@/common/styles';

export interface ITeamMember {
  id: number;
  full_name: string;
  title: string | null;
  description: string;
  image_url: string | null;
}

export interface IWhoAreWePagePageContent extends IPageContent {
  team_members: ITeamMember[];
}

export const WhoAreWePage: FC<IPageWithContentComponent<IWhoAreWePagePageContent>> = ({
  content,
}) => {
  return (
    <div
      style={getBgImageStyle(content.background_image_url)}
      className="flex flex-1 bg-no-repeat bg-cover"
    >
      <div className="flex flex-1 overflow-y-auto">
        <div
          className="container mx-auto w-full h-max backdrop-blur-xs justify-start min-h-full text-white flex items-center py-10"
          data-testid="who-are-we-container"
        >
          <div className="flex flex-col gap-2 bg-training-details py-10 px-16 lg:max-w-[80%]">
            <PageDescription pageContent={content} paragraphClassname="leading-[1.8]" />
            <TeamList teamMembers={content.team_members} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoAreWePage;
