export const GraduationCapIcon = ({ size = '21', highlight = false, stroke = '' }) => {
  const strokeColor = stroke ? stroke : highlight ? '#35a5f3' : 'currentColor';
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8333 8.83347V13.8335M18.35 9.6018C18.4991 9.53599 18.6257 9.42786 18.7141 9.2908C18.8024 9.15375 18.8486 8.99379 18.8469 8.83075C18.8452 8.66771 18.7957 8.50874 18.7046 8.37354C18.6134 8.23834 18.4846 8.13285 18.3341 8.07013L11.1916 4.8168C10.9745 4.71776 10.7386 4.6665 10.5 4.6665C10.2613 4.6665 10.0254 4.71776 9.80829 4.8168L2.66662 8.0668C2.51826 8.13178 2.39205 8.23858 2.30343 8.37415C2.2148 8.50971 2.1676 8.66817 2.1676 8.83014C2.1676 8.9921 2.2148 9.15055 2.30343 9.28612C2.39205 9.42169 2.51826 9.52849 2.66662 9.59347L9.80829 12.8501C10.0254 12.9492 10.2613 13.0004 10.5 13.0004C10.7386 13.0004 10.9745 12.9492 11.1916 12.8501L18.35 9.6018Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 10.917V13.8337C5.5 14.4967 6.02678 15.1326 6.96447 15.6014C7.90215 16.0703 9.17392 16.3337 10.5 16.3337C11.8261 16.3337 13.0979 16.0703 14.0355 15.6014C14.9732 15.1326 15.5 14.4967 15.5 13.8337V10.917"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GraduationCapIcon;
