import { DOStatus, SimpleTrainingRequest } from '../../common/api/digitalOffice';
import dayjs from 'dayjs';

export const getAgencies = (trainingRequest: SimpleTrainingRequest | null | undefined) => {
  if (!trainingRequest) return '';
  const result = trainingRequest.agencies?.map(agency => agency.short_name || agency.name) || [];
  if (trainingRequest.other_agencies?.trim()) result.push(trainingRequest.other_agencies.trim());
  return result.join(', ');
};

export const getEventDate = (trainingRequest: SimpleTrainingRequest | null | undefined) => {
  if (!trainingRequest) return '';

  const startDate = dayjs(trainingRequest.start_date).format('DD MMM YYYY');
  const endDate = dayjs(trainingRequest.end_date).format('DD MMM YYYY');
  return [startDate, endDate].join(' - ');
};

export const mapStatusToOption = (statuses: DOStatus[]) => {
  const result = {} as Record<string, DOStatus>;
  statuses.forEach(status => {
    result[status.status] = status;
  });
  return result;
};
