export const CalendarOptionIcon = () => (
  <svg width="21" height="21" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.25 13.125H14.375V11.25H13.125V13.125H11.25V14.375H13.125V16.25H14.375V14.375H16.25V13.125Z"
      fill="currentColor"
    />
    <path
      d="M13.75 18.75C11 18.75 8.75 16.5 8.75 13.75C8.75 11 11 8.75 13.75 8.75C16.5 8.75 18.75 11 18.75 13.75C18.75 16.5 16.5 18.75 13.75 18.75ZM13.75 10C11.6875 10 10 11.6875 10 13.75C10 15.8125 11.6875 17.5 13.75 17.5C15.8125 17.5 17.5 15.8125 17.5 13.75C17.5 11.6875 15.8125 10 13.75 10Z"
      fill="currentColor"
    />
    <path
      d="M17.5 3.75C17.5 3.0625 16.9375 2.5 16.25 2.5H13.75V1.25H12.5V2.5H7.5V1.25H6.25V2.5H3.75C3.0625 2.5 2.5 3.0625 2.5 3.75V16.25C2.5 16.9375 3.0625 17.5 3.75 17.5H7.5V16.25H3.75V3.75H6.25V5H7.5V3.75H12.5V5H13.75V3.75H16.25V7.5H17.5V3.75Z"
      fill="currentColor"
    />
  </svg>
);

export default CalendarOptionIcon;
