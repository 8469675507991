import { FC } from 'react';
import cn from 'classnames';

// null - correct value, string - warning message
export type ValidationResult = string | null;

export const nonEmptyValidation = (value: any) => {
  const emptyFieldMessage = 'This field is required';
  if (Array.isArray(value)) return value.length === 0 ? emptyFieldMessage : null;
  return [undefined, null, ''].includes(value) ? emptyFieldMessage : null;
};

export const phoneValidation = (value: string | null | undefined) => {
  const re = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
  return !re.test(value ?? '') ? 'Enter a valid phone number' : null;
};

export const nonEmptyPhoneValidation = (value: string | null | undefined) => {
  return nonEmptyValidation(value) || phoneValidation(value);
};

export const emailValidation = (value: string | null | undefined) => {
  const re = /^\S+@\S+\.\S+$/;
  return !re.test(value ?? '') ? 'Enter a valid email address' : null;
};

export const nonEmptyEmailValidation = (value: string | null | undefined) => {
  return nonEmptyValidation(value) || emailValidation(value);
};

interface IFieldValidationWarning {
  result: ValidationResult;
  light?: boolean;
}

export const FieldValidationWarning: FC<IFieldValidationWarning> = ({ result, light = false }) => {
  return (
    <div className={cn('h-5 -mb-5 text-sm', light ? 'text-red-600' : 'text-red-300')}>{result}</div>
  );
};
