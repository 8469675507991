import { InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import { FC, useEffect } from 'react';
import { IEditComponent } from './types';
import {
  IContactPerson,
  ISupervisor,
} from '@/components/forms/RequestTrainingForm/common/interfaces';
import {
  FieldValidationWarning,
  nonEmptyEmailValidation,
  nonEmptyPhoneValidation,
  nonEmptyValidation,
} from '@/components/forms/RequestTrainingForm/common/FieldValidationWarning';

export const CommunicationsEdit: FC<IEditComponent> = ({
  trainingRequest,
  updateTrainingRequest,
  setConfirmEnabled,
  context,
}) => {
  const updateFocalPoint = (field: keyof IContactPerson, value: string) => {
    updateTrainingRequest('focal_point', { ...trainingRequest.focal_point, [field]: value });
  };
  const updateSupervisor = (field: keyof ISupervisor, value: string) => {
    updateTrainingRequest('supervisor', { ...trainingRequest.supervisor, [field]: value });
  };
  const supervisorOptions = (context?.supervisor_roles || []) as InputDropdownItem[];

  const supervisorValidations = {
    first_name: nonEmptyValidation(trainingRequest.supervisor?.first_name),
    last_name: nonEmptyValidation(trainingRequest.supervisor?.last_name),
    email: nonEmptyEmailValidation(trainingRequest.supervisor?.email),
    // phone_number: nonEmptyPhoneValidation(trainingRequest.supervisor?.phone_number),
    role: nonEmptyValidation(trainingRequest.supervisor?.role),
  };
  const focalPointValidations = {
    first_name: nonEmptyValidation(trainingRequest.focal_point?.first_name),
    last_name: nonEmptyValidation(trainingRequest.focal_point?.last_name),
    phone_number: nonEmptyPhoneValidation(trainingRequest.focal_point?.phone_number),
    email: nonEmptyEmailValidation(trainingRequest.focal_point?.email),
  };

  const validators = [
    ...Object.values(supervisorValidations),
    ...Object.values(focalPointValidations),
  ];
  const confirmEnabled = validators.every(validator => validator === null);

  useEffect(() => {
    setConfirmEnabled(confirmEnabled);
  }, [confirmEnabled]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col items-stretch gap-6">
        <div>
          Please, provide the details of the head of office/head of department/fleet manager
        </div>
        <div className="flex flex-col">
          <InputDropdown
            light
            value={trainingRequest.supervisor?.role as string}
            onChange={e => updateSupervisor('role', e.target.value)}
            items={supervisorOptions}
            name="supervisor-role"
            placeholder="Select the role"
          />
          <FieldValidationWarning light result={supervisorValidations.role} />
        </div>
        <div className="flex flex-col">
          <InputText
            light
            value={trainingRequest.supervisor?.first_name ?? ''}
            onChange={e => updateSupervisor('first_name', e.target.value)}
            name="supervisor-first-name"
            placeholder="First name"
          />
          <FieldValidationWarning light result={supervisorValidations.first_name} />
        </div>
        <div className="flex flex-col">
          <InputText
            light
            value={trainingRequest.supervisor?.last_name ?? ''}
            onChange={e => updateSupervisor('last_name', e.target.value)}
            name="supervisor-last-name"
            placeholder="Last name"
          />
          <FieldValidationWarning light result={supervisorValidations.last_name} />
        </div>
        <div className="flex flex-col">
          <InputText
            light
            value={trainingRequest.supervisor?.email ?? ''}
            onChange={e => updateSupervisor('email', e.target.value)}
            name="supervisor-email"
            placeholder="Email"
          />
          <FieldValidationWarning light result={supervisorValidations.email} />
        </div>
      </div>
      <div className="flex flex-col items-stretch gap-6">
        <div>
          Name the primary focal point for all driver training communication and coordination and
          provide us with their phone number
        </div>
        <div className="flex flex-col">
          <InputText
            light
            value={trainingRequest.focal_point?.first_name ?? ''}
            onChange={e => updateFocalPoint('first_name', e.target.value)}
            name="focal-point-first-name"
            placeholder="First name"
          />
          <FieldValidationWarning light result={focalPointValidations.first_name} />
        </div>
        <div className="flex flex-col">
          <InputText
            light
            value={trainingRequest.focal_point?.last_name ?? ''}
            onChange={e => updateFocalPoint('last_name', e.target.value)}
            name="focal-point-last-name"
            placeholder="Last name"
          />
          <FieldValidationWarning light result={focalPointValidations.last_name} />
        </div>
        <div className="flex flex-col">
          <InputText
            light
            value={trainingRequest.focal_point?.phone_number ?? ''}
            onChange={e => updateFocalPoint('phone_number', e.target.value)}
            name="focal-point-phone-number"
            placeholder="Phone Number"
          />
          <FieldValidationWarning light result={focalPointValidations.phone_number} />
        </div>
        <div className="flex flex-col">
          <InputText
            light
            value={trainingRequest.focal_point?.email ?? ''}
            onChange={e => updateFocalPoint('email', e.target.value)}
            name="focal-point-email"
            placeholder="Email"
          />
          <FieldValidationWarning light result={focalPointValidations.email} />
        </div>
      </div>
    </div>
  );
};

export default CommunicationsEdit;
