import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { IPageContent } from '@/common/api/cms';
import { getTrainingPrograms } from '@/common/api/trainings';
import { IPageWithContentComponent } from '@/common/interfaces';
import { getBgImageStyle } from '@/common/styles';
import BookTrainingNavButton from '../../components/buttons/BookTrainingNavButton';
import PageDescription from '../../components/cms/PageDescription';
import { TrainingProgramsList } from '@/components/trainings-programs/TrainingProgramsList';
import { QuestionMark } from '@icons';

export interface ITrainingProgramsPageContent extends IPageContent {
  bottom_note: string;
}

export const TrainingProgramsPage: FC<IPageWithContentComponent<ITrainingProgramsPageContent>> = ({
  content,
}) => {
  const { isError, data: response } = useQuery({
    queryKey: ['training-programs'],
    queryFn: getTrainingPrograms,
  });

  return (
    <div
      style={getBgImageStyle(content.background_image_url)}
      className="flex flex-1 bg-no-repeat bg-cover"
    >
      <div
        className="container mx-auto w-full h-max backdrop-blur-xs justify-center min-h-full text-white lg:flex items-stretch gap-24 p-6"
        data-testid="trainings-container"
      >
        <div className="basis-full lg:basis-1/3 flex flex-col justify-around mb-3">
          <div className="flex flex-col gap-5">
            <PageDescription pageContent={content} />
            <div className="flex flex-row gap-3">
              <BookTrainingNavButton />
            </div>
          </div>
          <div className="flex gap-2">
            {content.bottom_note ? <QuestionMark /> : null}
            <div
              className="text-default font-light"
              dangerouslySetInnerHTML={{ __html: content.bottom_note }}
            />
          </div>
        </div>
        <div className="basis-full lg:basis-2/3 mt-16">
          {isError ? (
            <div>Loading the training programs list unexpectedly failed.</div>
          ) : response ? (
            <TrainingProgramsList trainingPrograms={response.data.results} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TrainingProgramsPage;
