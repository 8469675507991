import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { getPageContent } from '@/common/api/cms';
import { IPage } from '@/common/interfaces';

interface IPageContentLoader {
  page: IPage;
}

export const PageContentProvider: FC<IPageContentLoader> = ({ page }) => {
  const {
    isPending,
    isError,
    data: response,
  } = useQuery({
    queryKey: [`${page.slug}Content`],
    queryFn: () => getPageContent(page.slug),
  });

  if (!page.shouldLoadContent) return <page.component content={null} />;

  if (isError)
    return (
      <p className="text-white">There was an unexpected error while loading the page content</p>
    );
  else if (isPending) return null; // todo: consider adding indication that it's loading
  else return <page.component content={response.data} />;
};

export default PageContentProvider;
