import { FC } from 'react';
import PageDescription from '../../components/cms/PageDescription';
import { IPageWithContentComponent } from '@/common/interfaces';
import { getBgImageStyle } from '@/common/styles';
import { DigitalOfficeDashboard } from '@/components/digital-office/DigitalOfficeDashboard';

export const DigitalOfficePage: FC<IPageWithContentComponent> = ({ content }) => {
  return (
    <div
      style={getBgImageStyle(content.background_image_url)}
      className="flex flex-1 bg-no-repeat bg-cover bg-center"
    >
      <div className="flex flex-1 overflow-y-auto bg-gradient-to-r from-black to-transparent">
        <div
          className="container mx-auto w-full h-max backdrop-blur-xs text-white p-10 mt-12"
          data-testid="home-container"
        >
          <div className="w-[55%] min-w-[min(700px,100%)] flex flex-col gap-10 xl:gap-16">
            <PageDescription pageContent={content} titleClassname="leading-normal" />
            <DigitalOfficeDashboard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalOfficePage;
