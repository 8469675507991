import { capitalize } from '../../../../common/utils';
import { TrainingRequestDetail } from '../../../../common/api/digitalOffice';
import { getEventDate } from '../../utils';
import { SupervisorRole } from '../../../forms/RequestTrainingForm/common/interfaces';

const EMPTY_LABEL = '-';

const yesNoLabel = (value: boolean | null, nullLabel = EMPTY_LABEL) =>
  value ? 'Yes' : value === false ? 'No' : nullLabel;

const addLine = (label: string, value: string | null | undefined) => {
  return { label, value: value || EMPTY_LABEL };
};

const addSubtitle = (label: string) => {
  return { type: 'subtitle', label, value: '' };
};

const addOptionalLine = (label: string, value: string | null | undefined) => {
  return value ? [{ label, value }] : [];
};

export const getAgencyData = (trainingRequest: TrainingRequestDetail) => {
  const agencyNames =
    trainingRequest.agencies?.map(({ short_name, name }) => short_name || name) ?? [];
  return [
    addLine('Selected agencies', agencyNames.join(', ')),
    ...addOptionalLine('Agencies missing from the list', trainingRequest.other_agencies),
  ];
};

export const getTimeAndLocationData = (trainingRequest: TrainingRequestDetail) => {
  return [
    addLine('C.Os preferred dates', getEventDate(trainingRequest)),
    addLine('Reason', trainingRequest.reason),
    addLine(
      'Day of the week training should NOT take place',
      capitalize(trainingRequest.weekend_training_days?.toLowerCase()),
    ),
    addLine('Preferred country', trainingRequest.country_location_name),
    addLine("Preferred 'in country' locations", trainingRequest.preferred_place),
    // addLine(
    //   'Functional classroom / projector / IT available?',
    //   yesNoLabel(trainingRequest.projector_available),
    // ),
    // addLine('30 traffic cones available?', yesNoLabel(trainingRequest.cones_available)),
    addLine('Working week', trainingRequest.co_working_days),
    addLine('Working hours', trainingRequest.co_working_hours),
  ];
};

export const getVehiclesData = (trainingRequest: TrainingRequestDetail) => {
  const recoveryEquipment = trainingRequest.recovery_equipment?.length
    ? trainingRequest.recovery_equipment
        .map(({ label }) => label)
        .filter(Boolean)
        .join(', ')
    : null;

  return [
    addLine(
      '4-5 vehicles are available for the duration of the training?',
      yesNoLabel(trainingRequest.av_available),
    ),
    addLine('Training ground is available?', yesNoLabel(trainingRequest.safe_area)),
    ...addOptionalLine('Which recovery equipment is available in the vehicles?', recoveryEquipment),
  ];
};

export const getAttendeesData = (trainingRequest: TrainingRequestDetail) => {
  return [
    addLine('Number of participants', trainingRequest.num_of_attendees),
    addLine(
      'All the WFP participating drivers completed the mandatory e-learnings',
      yesNoLabel(trainingRequest.elearning_completed),
    ),
  ];
};

export const getCommunicationsData = (trainingRequest: TrainingRequestDetail) => {
  // Remove check in SupervisorRole after BE is ready
  const role =
    trainingRequest.supervisor?.role_display ||
    SupervisorRole[trainingRequest.supervisor?.role as unknown as keyof typeof SupervisorRole] ||
    trainingRequest.supervisor?.role;
  const data = [
    addSubtitle('Head of office/head of department/fleet manager'),
    addLine('Role', role),
    addLine('First Name', trainingRequest.supervisor?.first_name),
    addLine('Last Name', trainingRequest.supervisor?.last_name),
    addLine('Email', trainingRequest.supervisor?.email),
  ];
  if (trainingRequest.focal_point) {
    data.push(
      ...[
        addSubtitle('Primary focal point'),
        addLine('First Name', trainingRequest.focal_point.first_name),
        addLine('Last Name', trainingRequest.focal_point.last_name),
        addLine('Phone Number', trainingRequest.focal_point.phone_number),
        addLine('Email', trainingRequest.focal_point.email),
      ],
    );
  }
  return data;
};

export const getTrainerData = (trainingRequest: TrainingRequestDetail) => {
  return [
    addLine(
      'Is a pre-approved visa required when holding a UN laissez-passer?',
      yesNoLabel(trainingRequest.trainers_require_visa),
    ),
    ...addOptionalLine(
      'Visa details',
      trainingRequest.trainers_require_visa ? trainingRequest.trainers_require_visa_comment : null,
    ),
    addLine(
      'Trainers require any medical clearance for entry?',
      yesNoLabel(trainingRequest.trainers_require_medical_clearence),
    ),
    ...addOptionalLine(
      'Medical clearance details',
      trainingRequest.trainers_require_medical_clearence
        ? trainingRequest.trainers_require_medical_clearence_comment
        : null,
    ),
    addLine("Travel or 'in-country' advice", trainingRequest.travel_advice),
  ];
};
