import { InputText } from '@unbooking/ui-kit';
import { FC, useEffect, useMemo } from 'react';
import YesNoInput from '../../../inputs/YesNoInput';
import { IRequestTrainingFormStepProps, RequestTrainingStep } from '../common/interfaces';
import BaseStep from './components/BaseStep';
import { FieldValidationWarning, nonEmptyValidation } from '../common/FieldValidationWarning';
import { InfoIcon } from '@icons';
import { RequiredMark, RequiredUnderline, Tip } from '@components';

const STEP = RequestTrainingStep.TRAINER;

export const TrainerStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  updateFormData,
  goToNextStep,
  goToPreviousStep,
  updateStepCompleted,
}) => {
  const formStep = formData[STEP];

  const availableFields = useMemo(() => {
    const fields = ['trainers_require_visa', 'trainers_require_medical_clearence'];
    if (formStep.trainers_require_visa) fields.push('trainers_require_visa_comment');
    if (formStep.trainers_require_medical_clearence)
      fields.push('trainers_require_medical_clearence_comment');
    return fields;
  }, [formStep.trainers_require_visa, formStep.trainers_require_medical_clearence]);

  const fieldValidators: Record<string, string | null> = {
    trainers_require_visa: nonEmptyValidation(formStep.trainers_require_visa),
    trainers_require_visa_comment: nonEmptyValidation(formStep.trainers_require_visa_comment),
    trainers_require_medical_clearence: nonEmptyValidation(
      formStep.trainers_require_medical_clearence,
    ),
    trainers_require_medical_clearence_comment: nonEmptyValidation(
      formStep.trainers_require_medical_clearence_comment,
    ),
  };
  const nextStepEnabled = availableFields.every(field => fieldValidators[field] === null);

  useEffect(() => {
    updateStepCompleted(STEP, nextStepEnabled);
  }, [nextStepEnabled]);

  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextStepEnabled={nextStepEnabled}
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col items-stretch mb-2 gap-2">
          <div className="text-white">
            Is a pre-approved visa required when holding a UN laissez-passer?{' '}
            <Tip text="A United Nations laissez-passer (UNLP) is a diplomatic travel document issued by the United Nations">
              <InfoIcon size="18px" />
            </Tip>
            <RequiredMark />
          </div>
          <div>
            <YesNoInput
              value={formStep.trainers_require_visa}
              onChange={value => updateFormData('trainers_require_visa', value)}
            />
            {fieldValidators.trainers_require_visa && <RequiredUnderline />}
            {/* <FieldValidationWarning result={fieldValidators.trainers_require_visa} /> */}
          </div>
          {formStep.trainers_require_visa && (
            <div className="flex flex-col">
              <InputText
                placeholder="Please provide details regarding the visa process and duration"
                value={formStep.trainers_require_visa_comment}
                onChange={e => updateFormData('trainers_require_visa_comment', e.target.value)}
                disabled={!formStep.trainers_require_visa}
                name="trainer-visa-comment"
              />
              {fieldValidators.trainers_require_visa_comment && <RequiredUnderline />}
              {/* <FieldValidationWarning result={fieldValidators.trainers_require_visa_comment} /> */}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col items-stretch mb-2 gap-2">
          <div className="text-white">
            Do the trainers require any medical clearance for entry (yellow fever, HIV, TB, etc.)?
            <RequiredMark />
          </div>
          <div>
            <YesNoInput
              value={formStep.trainers_require_medical_clearence}
              onChange={value => updateFormData('trainers_require_medical_clearence', value)}
            />
            {fieldValidators.trainers_require_medical_clearence && <RequiredUnderline />}
            {/* <FieldValidationWarning result={fieldValidators.trainers_require_medical_clearence} /> */}
          </div>
          {formStep.trainers_require_medical_clearence && (
            <div className="flex flex-col">
              <InputText
                placeholder="Please provide details regarding the required medical clearance"
                value={formStep.trainers_require_medical_clearence_comment}
                onChange={e =>
                  updateFormData('trainers_require_medical_clearence_comment', e.target.value)
                }
                name="trainer-medical-comment"
              />

              {fieldValidators.trainers_require_medical_clearence_comment && <RequiredUnderline />}
              {/* <FieldValidationWarning
                result={fieldValidators.trainers_require_medical_clearence_comment}
              /> */}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col items-stretch mb-2 gap-2">
          <div className="text-white">
            Is there any additional travel or 'in-country' advice for the RSA trainer?{' '}
            <i>(optional)</i>
          </div>
          <InputText
            placeholder="..."
            value={formStep.travel_advice}
            onChange={e => updateFormData('travel_advice', e.target.value)}
            name="travel-advice"
          />
        </div>
      </div>
    </BaseStep>
  );
};
