import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from '@unbooking/ui-auth';

import { IPage } from '@/common/interfaces';
import PageContentProvider from '../../components/cms/PageContentProvider';
import { DefaultMenuPages, NoMenuPages, DigitalOfficePages } from './pages';
import { RootLayout, SignInPage } from '../../pages';

export function RoadSafetyRouter() {
  const { isAuthenticated } = useAuth();

  const pageToRoute = (page: IPage) => {
    const element = page.shouldLoadContent ? (
      <PageContentProvider page={page} />
    ) : (
      <page.component content={null} />
    );
    return <Route path={page.path} element={element} key={page.path} />;
  };

  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Routes>
        {isAuthenticated ? (
          <>
            <Route element={<RootLayout menu="default" />}>
              {Object.values(DefaultMenuPages).map(pageToRoute)}
            </Route>
            <Route element={<RootLayout menu="none" />}>
              {Object.values(NoMenuPages).map(pageToRoute)}
            </Route>
            <Route element={<RootLayout menu="digital-office" />}>
              {Object.values(DigitalOfficePages).map(pageToRoute)}
            </Route>
          </>
        ) : (
          <>
            {/*Used in Sign in flow*/}
            <Route path="/redirect" element={<SignInPage />} />
            <Route path="*" element={<SignInPage />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default RoadSafetyRouter;
