import { FC } from 'react';
import cn from 'classnames';

interface IArrowIcon {
  direction?: 'left' | 'right';
  className?: string;
}

export const ArrowIcon: FC<IArrowIcon> = ({ direction = 'right', className }) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn(direction === 'left' && 'rotate-180', className)}
  >
    <path
      d="M9.25 0.75L8.35625 1.62063L13.0938 6.375H0.5V7.625H13.0938L8.35625 12.3581L9.25 13.25L15.5 7L9.25 0.75Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowIcon;
