import { FC } from 'react';
import {
  TrainerIcon,
  AgencyIcon,
  BookIcon,
  LocationIcon,
  VehicleIcon,
  AttendeesIcon,
  CommunicationIcon,
  GraduationCapIcon,
} from '@icons';
import { IRequestTrainingFormStepProps, RequestTrainingStep } from './common/interfaces';
import {
  AgencyStep,
  VehiclesStep,
  AttendeesStep,
  TrainerStep,
  ConfirmStep,
  TimeAndLocationStep,
  CommunicationsStep,
} from './FormStepComponents';
import { ITrainingProgram } from '@/common/api/trainings';
import ProgramStep from '@/components/forms/RequestTrainingForm/FormStepComponents/ProgramStep';

interface IRequestTrainingFormStep {
  step: RequestTrainingStep;
  label: string;
  icon: FC;
  component: FC<IRequestTrainingFormStepProps>;
  pageLabel?: string;
}

const RequestTrainingFormSteps: IRequestTrainingFormStep[] = [
  {
    step: RequestTrainingStep.PROGRAM,
    label: 'Training program',
    icon: GraduationCapIcon,
    component: ProgramStep,
  },
  {
    step: RequestTrainingStep.AGENCY,
    label: 'Agency',
    icon: AgencyIcon,
    component: AgencyStep,
  },
  {
    step: RequestTrainingStep.TIME_AND_LOCATION,
    label: 'Time and Location',
    icon: LocationIcon,
    component: TimeAndLocationStep,
  },
  {
    step: RequestTrainingStep.VEHICLES,
    label: 'Vehicles',
    icon: VehicleIcon,
    component: VehiclesStep,
  },
  {
    step: RequestTrainingStep.ATTENDEES,
    label: 'Participants',
    icon: AttendeesIcon,
    component: AttendeesStep,
  },
  {
    step: RequestTrainingStep.COMMUNICATIONS,
    label: 'Communications',
    icon: CommunicationIcon,
    component: CommunicationsStep,
  },
  {
    step: RequestTrainingStep.TRAINER,
    label: 'Travel requirements for trainers',
    icon: TrainerIcon,
    component: TrainerStep,
  },
  {
    step: RequestTrainingStep.CONFIRM,
    label: 'Submit request',
    icon: BookIcon,
    component: ConfirmStep,
    pageLabel: 'Submit your request',
  },
];

const getStepsForProgram = (trainingProgram?: ITrainingProgram) => {
  return RequestTrainingFormSteps;
};

const getStepFormData = (step: RequestTrainingStep) =>
  RequestTrainingFormSteps.find(formStep => formStep.step === step) as IRequestTrainingFormStep;

const getPreviousStep = (step: RequestTrainingStep, trainingProgram?: ITrainingProgram) => {
  const steps = getStepsForProgram(trainingProgram);
  const index = steps.findIndex(formStep => formStep.step === step);
  const previousIndex = index === 0 ? 0 : index - 1;
  return steps[previousIndex].step;
};

const getNextFormStep = (step: RequestTrainingStep, trainingProgram?: ITrainingProgram) => {
  const steps = getStepsForProgram(trainingProgram);
  const index = steps.findIndex(formStep => formStep.step === step);
  return steps[index + 1]?.step;
};

export {
  IRequestTrainingFormStep,
  RequestTrainingFormSteps,
  getStepsForProgram,
  getStepFormData,
  getPreviousStep,
  getNextFormStep,
};
