import { FC } from 'react';
import cn from 'classnames';
import { IPageContent } from '@/common/api/cms';

interface IPageDescription {
  pageContent: IPageContent;
  titleClassname?: string;
  paragraphClassname?: string;
}

export const PageDescription: FC<IPageDescription> = ({
  pageContent,
  titleClassname,
  paragraphClassname,
}) => {
  return (
    <>
      <h1
        className={cn('text-5xl', titleClassname)}
        dangerouslySetInnerHTML={{ __html: pageContent.page_title }}
      />
      {pageContent.paragraphs.map(paragraph => (
        <p
          key={paragraph.id}
          className={cn('text-default font-light', paragraphClassname)}
          dangerouslySetInnerHTML={{ __html: paragraph.content }}
        ></p>
      ))}
    </>
  );
};

export default PageDescription;
