import React, { FC, useContext } from 'react';
import { IRequestTrainingFormData, RequestTrainingStep } from '../../common/interfaces';
import { RequestTrainingFormContext } from '../../common/formContext';
import FormSummarySection from './FormSummarySection';
import {
  AgencyIcon,
  AttendeesIcon,
  CommunicationIcon,
  GraduationCapIcon,
  LocationIcon,
  TrainerIcon,
  VehicleIcon,
} from '@icons';
import {
  getAgencyData,
  getAttendeesData,
  getCommunicationsData,
  getProgramData,
  getTimeAndLocationData,
  getTrainerData,
  getVehiclesData,
} from '../utils';

interface IFormSummary {
  formData: IRequestTrainingFormData;
  setSelectedStep: React.Dispatch<React.SetStateAction<RequestTrainingStep>>;
}

export const FormSummary: FC<IFormSummary> = ({ formData, setSelectedStep }) => {
  const { formContext } = useContext(RequestTrainingFormContext);

  const programData = getProgramData(formData[RequestTrainingStep.PROGRAM]);
  const agencyData = getAgencyData(
    formData[RequestTrainingStep.AGENCY],
    formContext?.agencies ?? [],
  );
  const timeAndLocationData = getTimeAndLocationData(
    formData[RequestTrainingStep.TIME_AND_LOCATION],
    formContext?.countries ?? [],
  );
  const vehiclesData = getVehiclesData(
    formData[RequestTrainingStep.VEHICLES],
    formContext?.recovery_equipment ?? [],
  );
  const attendeesData = getAttendeesData(formData[RequestTrainingStep.ATTENDEES]);
  const communicationsData = getCommunicationsData(
    formData[RequestTrainingStep.COMMUNICATIONS],
    formContext?.supervisor_roles ?? [],
  );
  const trainersData = getTrainerData(formData[RequestTrainingStep.TRAINER]);

  return (
    <div className="text-white font-light">
      <FormSummarySection
        icon={<GraduationCapIcon highlight={true} />}
        title="Training program"
        data={programData}
        onEdit={() => setSelectedStep(RequestTrainingStep.PROGRAM)}
      />
      <FormSummarySection
        icon={<AgencyIcon />}
        title="Agency"
        data={agencyData}
        onEdit={() => setSelectedStep(RequestTrainingStep.AGENCY)}
      />
      <FormSummarySection
        icon={<LocationIcon highlight={true} />}
        title="Time and Location"
        data={timeAndLocationData}
        onEdit={() => setSelectedStep(RequestTrainingStep.TIME_AND_LOCATION)}
      />
      <FormSummarySection
        icon={<VehicleIcon />}
        title="Vehicles"
        data={vehiclesData}
        onEdit={() => setSelectedStep(RequestTrainingStep.VEHICLES)}
      />
      <FormSummarySection
        icon={<AttendeesIcon />}
        title="Participants"
        data={attendeesData}
        onEdit={() => setSelectedStep(RequestTrainingStep.ATTENDEES)}
      />
      <FormSummarySection
        icon={<CommunicationIcon />}
        title="Communications"
        data={communicationsData}
        onEdit={() => setSelectedStep(RequestTrainingStep.COMMUNICATIONS)}
      />
      <FormSummarySection
        icon={<TrainerIcon />}
        title="Travel requirements for trainers"
        data={trainersData}
        onEdit={() => setSelectedStep(RequestTrainingStep.TRAINER)}
      />
    </div>
  );
};

export default FormSummary;
