import IconTitle from '../../../../other/IconTitle';
import { FC } from 'react';
import { RequestTrainingStep } from '../../common/interfaces';
import { getStepFormData } from '../../RequestTrainingFormSteps';

interface IStepTitle {
  formStep: RequestTrainingStep;
}

export const StepTitle: FC<IStepTitle> = ({ formStep }) => {
  const { label, pageLabel, icon } = getStepFormData(formStep);

  return <IconTitle label={pageLabel || label} icon={icon} />;
};

export default StepTitle;
