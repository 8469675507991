import { InputText } from '@unbooking/ui-kit';
import { FC, useEffect, useMemo } from 'react';
import YesNoInput from '../../../inputs/YesNoInput';
import { InfoIcon } from '@icons';
import { Tip } from '@components';
import { IEditComponent } from './types';
import {
  FieldValidationWarning,
  nonEmptyValidation,
} from '@/components/forms/RequestTrainingForm/common/FieldValidationWarning';

export const TrainerEdit: FC<IEditComponent> = ({
  trainingRequest,
  updateTrainingRequest,
  setConfirmEnabled,
}) => {
  const availableFields = useMemo(() => {
    const fields = ['trainers_require_visa', 'trainers_require_medical_clearence'];
    if (trainingRequest.trainers_require_visa) fields.push('trainers_require_visa_comment');
    if (trainingRequest.trainers_require_medical_clearence)
      fields.push('trainers_require_medical_clearence_comment');
    return fields;
  }, [trainingRequest.trainers_require_visa, trainingRequest.trainers_require_medical_clearence]);

  const fieldValidators: Record<string, string | null> = {
    trainers_require_visa: nonEmptyValidation(trainingRequest.trainers_require_visa),
    trainers_require_visa_comment: nonEmptyValidation(
      trainingRequest.trainers_require_visa_comment,
    ),
    trainers_require_medical_clearence: nonEmptyValidation(
      trainingRequest.trainers_require_medical_clearence,
    ),
    trainers_require_medical_clearence_comment: nonEmptyValidation(
      trainingRequest.trainers_require_medical_clearence_comment,
    ),
  };
  const confirmEnabled = availableFields.every(field => fieldValidators[field] === null);

  useEffect(() => {
    setConfirmEnabled(confirmEnabled);
  }, [confirmEnabled]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col items-stretch mb-2 gap-2">
        <div>
          Is a pre-approved visa required when holding a UN laissez-passer?{' '}
          <Tip text="A United Nations laissez-passer (UNLP) is a diplomatic travel document issued by the United Nations">
            <InfoIcon size="18px" />
          </Tip>
        </div>
        <div>
          <YesNoInput
            light
            value={trainingRequest.trainers_require_visa}
            onChange={value => updateTrainingRequest('trainers_require_visa', value)}
          />
          <FieldValidationWarning light result={fieldValidators.trainers_require_visa} />
        </div>
        {trainingRequest.trainers_require_visa && (
          <div className="flex flex-col">
            <InputText
              light
              placeholder="Please provide details regarding the visa process and duration"
              value={trainingRequest.trainers_require_visa_comment}
              onChange={e => updateTrainingRequest('trainers_require_visa_comment', e.target.value)}
              disabled={!trainingRequest.trainers_require_visa}
              name="trainer-visa-comment"
            />
            <FieldValidationWarning light result={fieldValidators.trainers_require_visa_comment} />
          </div>
        )}
      </div>
      <div className="flex flex-col items-stretch mb-2 gap-2">
        <div>
          Do the trainers require any medical clearance for entry (yellow fever, HIV, TB, etc.)?
        </div>
        <div>
          <YesNoInput
            light
            value={trainingRequest.trainers_require_medical_clearence}
            onChange={value => updateTrainingRequest('trainers_require_medical_clearence', value)}
          />
          <FieldValidationWarning
            light
            result={fieldValidators.trainers_require_medical_clearence}
          />
        </div>
        {trainingRequest.trainers_require_medical_clearence && (
          <div className="flex flex-col">
            <InputText
              light
              placeholder="Please provide details regarding the required medical clearance"
              value={trainingRequest.trainers_require_medical_clearence_comment}
              onChange={e =>
                updateTrainingRequest('trainers_require_medical_clearence_comment', e.target.value)
              }
              name="trainer-medical-comment"
            />

            <FieldValidationWarning
              light
              result={fieldValidators.trainers_require_medical_clearence_comment}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col items-stretch mb-2 gap-2">
        <div>
          Is there any additional travel or 'in-country' advice for the RSA trainer?{' '}
          <i>(optional)</i>
        </div>
        <InputText
          light
          placeholder="..."
          value={trainingRequest.travel_advice ?? ''}
          onChange={e => updateTrainingRequest('travel_advice', e.target.value)}
          name="travel-advice"
        />
      </div>
    </div>
  );
};

export default TrainerEdit;
