import React from 'react';

interface IconWrapperProps {
  children: React.ReactNode;
}

export const IconWrapper: React.FC<IconWrapperProps> = ({ children }) => {
  return (
    <div
      role="presentation"
      className={`w-[45px] h-[45px] bg-mabel flex items-center justify-center rounded-[50px]`}
    >
      {children}
    </div>
  );
};
