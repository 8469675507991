import { FC } from 'react';

export const AgencyIcon: FC<{ size?: string }> = ({ size = '21' }) => (
  <svg
    data-testid="agency-icon"
    width={size}
    height={size}
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.83332 18C3.37499 18 2.98277 17.8369 2.65666 17.5108C2.33055 17.1847 2.16721 16.7922 2.16666 16.3333V7.16663C2.16666 6.70829 2.32999 6.31607 2.65666 5.98996C2.98332 5.66385 3.37555 5.50051 3.83332 5.49996H7.16666V3.83329C7.16666 3.37496 7.32999 2.98274 7.65666 2.65663C7.98332 2.33051 8.37555 2.16718 8.83332 2.16663H12.1667C12.625 2.16663 13.0175 2.32996 13.3442 2.65663C13.6708 2.98329 13.8339 3.37551 13.8333 3.83329V5.49996H17.1667C17.625 5.49996 18.0175 5.66329 18.3442 5.98996C18.6708 6.31663 18.8339 6.70885 18.8333 7.16663V16.3333C18.8333 16.7916 18.6703 17.1841 18.3442 17.5108C18.018 17.8375 17.6255 18.0005 17.1667 18H3.83332ZM3.83332 16.3333H17.1667V7.16663H3.83332V16.3333ZM8.83332 5.49996H12.1667V3.83329H8.83332V5.49996Z" />
  </svg>
);

export default AgencyIcon;
