import { FC } from 'react';
import { Outlet } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import RoadSafetyHeader, { HeaderMenuType } from '../../app/header/header';
import RoadSafetyFooter from '../../components/other/RoadSafetyFooter';

export const RootLayout: FC<{ menu: HeaderMenuType }> = ({ menu = 'default' }) => {
  return (
    <div data-testid="layout" className="flex flex-col h-screen w-full overflow-hidden">
      <RoadSafetyHeader menu={menu} />
      <main className="bg-default bg-no-repeat bg-cover bg-center flex flex-1 overflow-y-auto font-sans bg-road-safety-bg">
        <Outlet />
      </main>
      <RoadSafetyFooter />
      <Tooltip id="global-tooltip" style={{ width: 'auto' }} />
      <ToastContainer
        theme="dark"
        position="top-center"
        closeButton={false}
        newestOnTop={true}
        closeOnClick
        limit={2}
      />
    </div>
  );
};

export default RootLayout;
