import React, { FC } from 'react';

import {
  getNextFormStep,
  getStepsForProgram,
  IRequestTrainingFormStep,
} from './RequestTrainingFormSteps';
import { CheckedIcon } from '@icons';
import { RequestTrainingStep } from './common/interfaces';
import { Tip } from '@components';

interface IRequestTrainingForm {
  selectedStep: RequestTrainingStep;
  setSelectedStep: React.Dispatch<React.SetStateAction<RequestTrainingStep>>;
  stepsCompleted: Record<RequestTrainingStep, boolean>;
}

interface IRequestTrainingFormSidebarStep extends IRequestTrainingForm {
  formStep: IRequestTrainingFormStep;
}

const RequestTrainingFormSidebarStep: FC<IRequestTrainingFormSidebarStep> = ({
  formStep,
  selectedStep,
  setSelectedStep,
  stepsCompleted,
}) => {
  const onClick = () => {
    if (!stepCanBeClicked || disableAllStepsClicks) return;
    setSelectedStep(formStep.step);
  };

  const colorClasses = {
    selected: {
      border: `border-[#35a5f3]`,
      text: 'text-white',
      icon: 'fill-highlight',
    },
    base: {
      border: `border-[#a0a6ac]`,
      text: `text-[#a0a6ac]`,
      icon: 'fill-unhighlight',
    },
  };
  const isSelected = selectedStep === formStep.step;
  const isCompleted = stepsCompleted[formStep.step];
  const nextStepCompleted = stepsCompleted[getNextFormStep(selectedStep)];
  // Check previous step competition
  const confirmStepClickable =
    formStep.step === RequestTrainingStep.CONFIRM && stepsCompleted[RequestTrainingStep.TRAINER];

  let stepCanBeClicked = false,
    disableAllStepsClicks = false;
  if (
    selectedStep !== RequestTrainingStep.CONFIRM &&
    !stepsCompleted[selectedStep] &&
    nextStepCompleted
  ) {
    disableAllStepsClicks = true;
  } else if ((isCompleted || confirmStepClickable) && !isSelected) {
    stepCanBeClicked = true;
  }

  const colorClass = isSelected || isCompleted ? colorClasses['selected'] : colorClasses['base'];

  return (
    <Tip
      text="Please fill in all required fields for the current step"
      isVisible={disableAllStepsClicks && isCompleted}
    >
      <div
        className={`border-l-2 ${colorClass['border']} pl-3 pb-7 pt-3 ${
          stepCanBeClicked && !disableAllStepsClicks ? 'cursor-pointer' : ''
        }`}
        onClick={onClick}
      >
        <div className={`flex flex-row font-light ${colorClass['text']} ${colorClass['icon']}`}>
          {isCompleted && !isSelected ? <CheckedIcon /> : <formStep.icon highlight={isSelected} />}
          <div className="ml-2 -mt-0.5">{formStep.label}</div>
        </div>
      </div>
    </Tip>
  );
};

export const RequestTrainingFormSidebar: FC<IRequestTrainingForm> = ({
  selectedStep,
  setSelectedStep,
  stepsCompleted,
}) => {
  const steps = getStepsForProgram();

  return (
    <div className="flex flex-col px-8 lg:whitespace-nowrap w-[220px] lg:w-auto">
      {steps.map(formStep => (
        <RequestTrainingFormSidebarStep
          key={formStep.step}
          formStep={formStep}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          stepsCompleted={stepsCompleted}
        />
      ))}
    </div>
  );
};

export default RequestTrainingFormSidebar;
