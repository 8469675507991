import { FC } from 'react';
import { Loader, LoaderProps } from '@unbooking/ui-kit';
import cn from 'classnames';

interface ISpinner extends LoaderProps {
  fullScreen?: boolean;
}

export const Spinner: FC<ISpinner> = ({ fullScreen = false, containerClassName, ...props }) => {
  return (
    <Loader
      containerClassName={cn(containerClassName, fullScreen && 'fixed top-1/2 left-1/2')}
      {...props}
    />
  );
};

export default Spinner;
