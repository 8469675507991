import { useQuery } from '@tanstack/react-query';
import { InputDropdown } from '@unbooking/ui-kit';
import { FC } from 'react';
import { getTrainingPrograms, ITrainingProgram } from '@/common/api/trainings';
import { Spinner } from '@components';

interface ITrainingProgramSelect {
  selectedProgram?: string;
  trackBy?: keyof ITrainingProgram;
  onProgramSelect: (program: string) => void;
  showLabel?: boolean;
  light?: boolean;
  placeholder?: string;
}

const programToOption = (program: ITrainingProgram, trackBy: keyof ITrainingProgram) => ({
  label: program.title,
  value: program[trackBy] ?? program.training_type,
});

const TrainingProgramSelect: FC<ITrainingProgramSelect> = ({
  selectedProgram,
  trackBy = 'training_type',
  onProgramSelect,
  showLabel = true,
  light = false,
  placeholder = 'Select a training program',
}) => {
  const { isError, data: response } = useQuery({
    queryKey: ['training-programs'],
    queryFn: getTrainingPrograms,
  });

  const options = response
    ? response.data.results.map(program => programToOption(program, trackBy))
    : [];

  if (isError) {
    return (
      <div>Loading the training programs list unexpectedly failed, please reload the page.</div>
    );
  }

  return (
    <div className="flex flex-col items-stretch">
      {showLabel && (
        <div className="text-white mb-2">Select the training program you want to request</div>
      )}
      {response ? (
        <InputDropdown
          value={selectedProgram}
          onChange={e => onProgramSelect(e.target.value)}
          items={options}
          name="training-program-select"
          placeholder={placeholder}
          light={light}
        />
      ) : (
        <div className="self-center">
          <Spinner width={50} height={50} />
        </div>
      )}
    </div>
  );
};

export { TrainingProgramSelect };
