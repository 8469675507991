import { InputText } from '@unbooking/ui-kit';
import { FC, useEffect } from 'react';
import YesNoInput from '../../../inputs/YesNoInput';
import { IRequestTrainingFormStepProps, RequestTrainingStep } from '../common/interfaces';
import BaseStep from './components/BaseStep';
import InputWarning from './components/InputWarning';
import { FieldValidationWarning, nonEmptyValidation } from '../common/FieldValidationWarning';
import { RequiredMark, RequiredUnderline } from '@/components/common';

const STEP = RequestTrainingStep.ATTENDEES;

export const AttendeesStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  updateFormData,
  goToNextStep,
  goToPreviousStep,
  updateStepCompleted,
}) => {
  const formStep = formData[STEP];

  const numOfAttendeesValidator = nonEmptyValidation(formStep.num_of_attendees);
  const elearingCompletedValidator = nonEmptyValidation(formStep.elearning_completed);
  const nextStepEnabled = [numOfAttendeesValidator, elearingCompletedValidator].every(
    validator => validator === null,
  );

  useEffect(() => {
    updateStepCompleted(STEP, nextStepEnabled);
  }, [nextStepEnabled]);

  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextStepEnabled={nextStepEnabled}
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col items-stretch">
          <div className="text-white mb-2">
            Number of participants
            <RequiredMark />
          </div>
          <InputText
            value={formStep.num_of_attendees}
            onChange={e => updateFormData('num_of_attendees', e.target.value)}
            name="num-of-attendees"
            placeholder="..."
          />
          {formStep.num_of_attendees ? (
            <div className="mt-2">
              <InputWarning
                text={
                  'Please note that additional details about each participant will be necessary at a later stage to confirm the training request (Name, Last Name, Driver Licence etc...).'
                }
              />
            </div>
          ) : null}
          {numOfAttendeesValidator && <RequiredUnderline />}
          {/* <FieldValidationWarning result={numOfAttendeesValidator} /> */}
        </div>
        <div className="flex flex-col items-stretch">
          <div className="text-white mb-2">
            Please confirm if all the WFP participating drivers completed the mandatory e-learnings
            (including the Road Safety e-learning)
            <RequiredMark />
          </div>
          <YesNoInput
            value={formStep.elearning_completed}
            onChange={value => updateFormData('elearning_completed', value)}
          />
          {elearingCompletedValidator && <RequiredUnderline />}
          {/* <FieldValidationWarning result={elearingCompletedValidator} /> */}
          {formStep.elearning_completed === false && (
            <div className="mt-2">
              <InputWarning
                text={
                  'Please note that mandatory learning is an essential tool to make the most out of the in-person training. We recommend all trainees complete it before the live session takes place.'
                }
              />
            </div>
          )}
        </div>
      </div>
    </BaseStep>
  );
};

export default AttendeesStep;
