import React, { FC, useMemo } from 'react';
import { InputDropdown, InputDropdownItem } from '@unbooking/ui-kit';
import StatusBlock from './StatusBlock';
import { DOStatus } from '@/common/api/digitalOffice';
import { mapStatusToOption } from '@/components/digital-office/utils';

interface IStatusDropdown {
  status: string;
  options: DOStatus[];
  onChange: (value: string) => void;
}

const StatusDropdown: FC<IStatusDropdown> = ({ status, options, onChange }) => {
  const statusToOption = useMemo(() => mapStatusToOption(options), [options]);

  const customRender = (item?: InputDropdownItem) => {
    if (!item) return '';
    return <StatusBlock status={item.value as string} statusToOption={statusToOption} />;
  };

  return (
    <InputDropdown
      value={status}
      onChange={e => onChange(e.target.value)}
      items={options as unknown as InputDropdownItem[]}
      name="status-dropdown"
      placeholder="Select the status"
      renderInput={item => customRender(item as InputDropdownItem)}
      renderOption={customRender}
      light
      fullWidth
    />
  );
};

export default StatusDropdown;
