import { TrainingType } from '@/common/enums';
import { FC, useContext, useEffect } from 'react';
import { RequestTrainingFormContext } from '../common/formContext';
import {
  IRequestTrainingFormStepProps,
  RequestTrainingFormField,
  RequestTrainingStep,
} from '../common/interfaces';
import BaseStep from './components/BaseStep';
import YesNoInput from '../../../inputs/YesNoInput';
import InputWarning from './components/InputWarning';
import { InputDropdown, InputDropdownItem } from '@unbooking/ui-kit';
import { FieldValidationWarning, nonEmptyValidation } from '../common/FieldValidationWarning';
import { RequiredMark, RequiredUnderline } from '@/components/common';

const STEP = RequestTrainingStep.VEHICLES;

export const VehiclesStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  updateFormData,
  goToNextStep,
  goToPreviousStep,
  updateStepCompleted,
}) => {
  const formStep = formData[STEP];
  const trainingProgram = formData[RequestTrainingStep.PROGRAM].program;
  const { formContext } = useContext(RequestTrainingFormContext);
  const availableFields: RequestTrainingFormField[] = ['safe_area', 'av_available'];
  if (trainingProgram?.training_type === TrainingType.ORT) {
    availableFields.push('recovery_equipment');
  }

  const fieldValidators: Record<string, string | null> = {
    av_available: nonEmptyValidation(formStep.av_available),
    safe_area: nonEmptyValidation(formStep.safe_area),
  };

  const nextStepEnabled = availableFields.every(
    field => !(field in fieldValidators) || fieldValidators[field] === null,
  );

  const recoveryEquipmentOptions = formContext?.recovery_equipment ?? [];

  useEffect(() => {
    updateStepCompleted(STEP, nextStepEnabled);
  }, [nextStepEnabled]);

  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextStepEnabled={nextStepEnabled}
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col items-stretch mb-2 gap-2">
          <div className="text-white">
            Please, note that 4-5 vehicles are required for the duration of the training (1 vehicle
            per 2 participants). Are they available?
            <RequiredMark />
          </div>
          <div>
            <YesNoInput
              value={formStep.av_available}
              onChange={value => updateFormData('av_available', value)}
            />
            {fieldValidators.av_available && <RequiredUnderline />}
            {/* <FieldValidationWarning result={fieldValidators.av_available} /> */}
          </div>
          {formStep.av_available === false && (
            <InputWarning
              text={
                'Please note that the above-mentioned resources & facilities are required to the training to take place. Depending on the type of training, it might be impossible to accommodate your request if requirements are not fully met.'
              }
            />
          )}
        </div>
        <div className="flex flex-col items-stretch mb-2 gap-2">
          <div className="text-white">
            Is there a safe, secure and spacious (open) training ground (minimum 300m x 50m)
            available where the dynamic driving techniques can be demonstrated and practiced?
            <RequiredMark />
          </div>
          <div>
            <YesNoInput
              value={formStep.safe_area}
              onChange={value => updateFormData('safe_area', value)}
            />
            {fieldValidators.safe_area && <RequiredUnderline />}
            {/* <FieldValidationWarning result={fieldValidators.safe_area} /> */}
          </div>
          {formStep.safe_area === false && (
            <InputWarning
              text={
                'Please note that the above-mentioned resources & facilities are required to the training to take place. Depending on the type of training, it might be impossible to accommodate your request if requirements are not fully met.'
              }
            />
          )}
        </div>
        {availableFields.includes('recovery_equipment') && (
          <div className="flex flex-col items-stretch mb-3">
            <div className="text-white mb-2">
              Which recovery equipment is available in the vehicles?
            </div>
            <InputDropdown
              value={formStep.recovery_equipment}
              onChange={e => updateFormData('recovery_equipment', e.target.value)}
              items={recoveryEquipmentOptions as InputDropdownItem[]}
              name="recovery-equipment-select"
              placeholder="Select equipment"
              multiple
            />
          </div>
        )}
      </div>
    </BaseStep>
  );
};

export default VehiclesStep;
