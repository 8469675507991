import dayjs from 'dayjs';

export const RANGE_SEPARATOR = ' - ';
export const WEEKEND_DAY_OPTIONS = [
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
  { value: 'SUNDAY', label: 'Sunday' },
];
export const CUSTOM_TIME_SLOTS = [
  {
    label: 'Next month',
    from: dayjs().add(1, 'month').startOf('month'),
    to: dayjs().add(1, 'month').endOf('month'),
  },
  {
    label: 'In 3 months',
    from: dayjs().add(3, 'month').startOf('month'),
    to: dayjs().add(3, 'month').endOf('month'),
  },
  {
    label: 'In 6 months',
    from: dayjs().add(6, 'month').startOf('month'),
    to: dayjs().add(6, 'month').endOf('month'),
  },
  {
    label: 'Next year',
    from: dayjs().add(1, 'year').startOf('year'),
    to: dayjs().add(1, 'year').endOf('year'),
  },
];
