import React, { FC } from 'react';

interface ICloseIcon {
  color?: string;
  width?: string | number;
  height?: string | number;
}

export const CloseIcon: FC<ICloseIcon> = ({ color, width, height = 18 }) => (
  <svg
    width={width ? width : height}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M13.5 5.7875L12.7125 5L9 8.7125L5.2875 5L4.5 5.7875L8.2125 9.5L4.5 13.2125L5.2875 14L9 10.2875L12.7125 14L13.5 13.2125L9.7875 9.5L13.5 5.7875Z"
        fill={color ? color : 'currentColor'}
      />
    </g>
  </svg>
);

export default CloseIcon;
